import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CadeiaValor } from '../models/cadeia-valor';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class CadeiaValorService {
  apiCadeiaValor = `${environment.urlApi}/cadeias-valores`;

  constructor(private http: HttpClient) {}

  public listarCadeiaValores() {
    return this.http.get<CadeiaValor[]>(`${this.apiCadeiaValor}`, httpOptions);
  }

  public buscarCadeiaValor(id: number) {
    return this.http.get<CadeiaValor>(
      `${this.apiCadeiaValor}/${id}`,
      httpOptions
    );
  }

  public inserirCadeiaValor(cadeiaValor: CadeiaValor) {
    return this.http.post<CadeiaValor>(
      `${this.apiCadeiaValor}`,
      cadeiaValor,
      httpOptions
    );
  }

  public deletarCadeiaValor(cadeiaValor: CadeiaValor) {
    return this.http.delete<CadeiaValor>(
      `${this.apiCadeiaValor}/${cadeiaValor.id}`,
      httpOptions
    );
  }

  public editarCadeiaValor(cadeiaValor: CadeiaValor) {
    return this.http.put<CadeiaValor>(
      `${this.apiCadeiaValor}/${cadeiaValor.id}`,
      cadeiaValor,
      httpOptions
    );
  }
}
