import { Lista } from '../models/lista';

export enum NaturezaEnum {
  PO = 'PO',
  AN = 'AN',
  GE = 'GE'
}

export const NATUREZA_MAP: Lista[] = [
  { id: NaturezaEnum.PO, descricao: 'Procedimental', detalhamento: 'A elaboração da entrega exige um trabalho baseado no cumprimento de procedimentos técnicos padrão, não sendo possível outra forma de realizá-los.' },
  { id: NaturezaEnum.AN, descricao: 'Analítico', detalhamento: ' A elaboração da entrega exige um trabalho baseado em análises técnicas, as quais direcionam os procedimentos de realização da atividade.' },
  { id: NaturezaEnum.GE, descricao: 'Gerencial', detalhamento: 'A elaboração da entrega exige um trabalho baseado em suporte a execução de tarefas procedimentais e analíticas por meio de diagnósticos e planejamentos, monitoramentos e avaliações do trabalho individual e coletivo.' },
];
