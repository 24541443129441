import { Component, ViewChild, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SenhaFormComponent } from '@dft/layout/senha/senha.component';
import { DialogService, PromptConfig } from '@dft/shared/dialog/dialog.service';
import { PessoaPerfil } from '@dft/shared/models/pessoa-perfil';
import { PessoaSessao } from '@dft/shared/models/usuario-sessao';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { PessoaService } from '@dft/shared/services/pessoa.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/security/auth/auth.service';
import { Senha } from 'src/app/shared/models/senha';
import { InfoUsuarioComponent } from '@dft/layout/header/info-usuario.component';
import { MatSidenav } from '@angular/material/sidenav';
import { TipoPerfilEnum } from 'src/app/shared/enums/tipo-perfil.enum';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit{

  TipoPerfilEnum: typeof TipoPerfilEnum = TipoPerfilEnum;
  badgevisible = false;
  isSmallScreen = false;
  gftBanner = '../../../assets/banner-gft.svg';
  senha$: Observable<any>;

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') drawer: MatDrawer;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private mensagemService: MensagemService,
    private pessoaService: PessoaService,
    private matDialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.checkScreenSize();
  }

  private checkScreenSize(): void {
    const isSmallScreen = this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);

    // You can adjust the logic based on your requirements
    if (isSmallScreen) {
      this.drawer.close();
      this.isSmallScreen = true;
    } else {
      this.drawer.open();
      this.isSmallScreen = false;
    }
  }

  badgevisibility() {
    this.badgevisible = true;
  }

  get usuarioLogado$() {
    return this.authService.pessoaLogada$;
  }

  get perfil$() {
    return this.authService.perfilSelecionado$;
  }

  isUsuarioAutenticado(): Observable<boolean> {
    return this.authService.pessoaLogada$.pipe(map((p) => !!p));
  }

  isAutenticacaoLocal(): boolean {
    return this.authService.isAutenticacaoLocal();
  }

  logout() {
    this.authService.logout();
  }

  visualizarInfosUsuario(pessoa: PessoaSessao, perfil: PessoaPerfil) {
    this.matDialog.open(InfoUsuarioComponent, {
      data: {pessoa, perfil, authLocal: this.isAutenticacaoLocal()}
    }).afterClosed().subscribe(op => {
      if (op) {
        if (op === 'logout') {
          this.logout();
        }
        if (op === 'senha') {
          this.trocarSenha();
        }
      }
    });
  }

  trocarSenha(): void {
    const promptConfig: PromptConfig = {
      formComponent: SenhaFormComponent,
      title: 'ALTERAR SENHA',
      close: 'CANCELAR',
      ok: 'ALTERAR',
    };

    this.dialogService.prompt(promptConfig).subscribe(
      (data: Senha) => {
        data.idUsuario = this.usuarioLogado$.value.id;
        this.pessoaService.trocarSenha(data).subscribe(
          () => this.mensagemService.info(`Senha alterada com sucesso!`),
          (error) => this.mensagemService.erro(error)
        );
      },
      (erro) => this.mensagemService.erro(erro)
    );
  }
}
