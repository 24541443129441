<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="pessoa" attr.aria-hidden="true">Pessoa</mat-label>
    <input
      matInput
      name="pessoa"
      aria-label="Nome da pessoa"
      [value]="form.controls['pessoa']?.value?.nome"
      disabled
    />
  </mat-form-field>
  
  <mat-label attr.aria-hidden="true">
    Tipo Afastamento
  </mat-label>
  <dft-multiselect
    formControlName="afastamento"
    placeholder="Selecione o tipo do afastamento"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeAfastamentos"
    campoDeTexto="nomeAfastamento">
  </dft-multiselect>

  <br/>

  <div class="flex-form-container">
    <mat-form-field appearance="outline" class="flex-ini-date">
        <mat-label attr.aria-hidden="true">Data Inicial</mat-label>
        <input aria-label="Data inicial do afastamento"
          matInput
          placeholder="Ex.: 01/01/2018"
          name="dataInicio"
          [matDatepicker]="dtInicio"
          formControlName="dataInicio"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="dtInicio"></mat-datepicker-toggle>
        <mat-datepicker #dtInicio startView="multi-year"> </mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="flex-fin-date">
        <mat-label attr.aria-hidden="true">Data Final</mat-label>
        <input aria-label="Data final do afastamento"
          matInput
          placeholder="Ex.: 15/01/2018"
          name="dataFim"
          [matDatepicker]="dtFim"
          formControlName="dataFim"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="dtFim"></mat-datepicker-toggle>
        <mat-datepicker #dtFim startView="multi-year"> </mat-datepicker>
      </mat-form-field>
    </div>

</form>
