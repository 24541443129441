import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/security/auth/auth.service';
import { TipoPerfilEnum } from 'src/app/shared/enums/tipo-perfil.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  TipoPerfilEnum: typeof TipoPerfilEnum = TipoPerfilEnum;

  constructor(private authService: AuthService) { }

  get perfil$() {
    return this.authService.perfilSelecionado$;
  }

  isUsuarioAutenticado(): Observable<boolean> {
    return this.authService.pessoaLogada$.pipe(map(p => !!p));
  }
}
