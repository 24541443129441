import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SituacaoFuncional } from '../models/situacao-funcional';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class SituacaoFuncionalService {

  constructor(private http: HttpClient) {}

  public listarSituacoesFuncionais(idOrgao: number): Observable<SituacaoFuncional[]> {
    return this.http.get<SituacaoFuncional[]>(`${environment.urlApi}/orgaos/${idOrgao}/situacoesfuncionais`, httpOptions);
  }

  public criarSituacaoFuncional(sitFuncional: SituacaoFuncional) {
    return this.http.post<SituacaoFuncional>(
      `${environment.urlApi}/orgaos/${sitFuncional.orgao.id}/situacoesfuncionais`,
      sitFuncional,
      httpOptions);
  }

  public editarSituacaoFuncional(sitFuncional: SituacaoFuncional) {
    return this.http.put<SituacaoFuncional>(
      `${environment.urlApi}/orgaos/${sitFuncional.orgao.id}/situacoesfuncionais/${sitFuncional.id}`,
      sitFuncional,
      httpOptions);
  }

  public excluirSituacaoFuncional(sitFuncional: SituacaoFuncional) {
    return this.http.delete<SituacaoFuncional>(
      `${environment.urlApi}/orgaos/${sitFuncional.orgao.id}/situacoesfuncionais/${sitFuncional.id}`
    );
  }
}
