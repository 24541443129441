<form [formGroup]="form" class="container-form">
  <br/>
  
  <mat-label attr.aria-hidden="true">
    Cadeia de Valor
  </mat-label>
  <dft-multiselect
    formControlName="cadeiaValor"
    placeholder="Selecione a Cadeia de Valor"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="cadeiaValores$ | async"
    ordenacao="nome"
    campoDeTexto="nome"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>

  <br/>

  <mat-label attr.aria-hidden="true">
    Produção dessa entrega pode ser prevista?
  </mat-label>
  
  <span *ngIf="capacidade.control?.value?.id" class="material-icons-outlined"
        mat-raised-button
        matTooltip="{{capacidade.control?.value?.descricao}} : {{capacidade.control?.value?.detalhamento}}"
        >help_outline</span>
  
  <dft-multiselect appearance="outline"
    formControlName="capacidade"
    placeholder="Selecione a capacidade de produção da entrega"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="capacidades"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #capacidade>
  </dft-multiselect>
  <br/>

  <mat-label attr.aria-hidden="true">
    A elaboração da entrega exige interação entre trabalhadores da unidade?
  </mat-label>
  
  <span *ngIf="interacao.control?.value?.id" class="material-icons-outlined"
        mat-raised-button
        matTooltip="{{interacao.control?.value?.descricao}} : {{interacao.control?.value?.detalhamento}}"
        >help_outline</span>
  
  <dft-multiselect appearance="outline"
    formControlName="interacao"
    placeholder="Selecione qual nível de interação é necessário na entrega"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="interacoes"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #interacao>
  </dft-multiselect>

  <br />
  
  <mat-label attr.aria-hidden="true">
    Qual tipo de trabalho é realizado na elaboração da atividade/entrega?
  </mat-label>
  
  <span *ngIf="natureza.control?.value?.id" class="material-icons-outlined"
        mat-raised-button
        matTooltip="{{natureza.control?.value?.descricao}} : {{natureza.control?.value?.detalhamento}}">
        help_outline
  </span>

  <dft-multiselect appearance="outline"
    formControlName="natureza"
    placeholder="Selecione a natureza da produção da entrega"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="naturezas"
    campoDeTexto="descricao"
    permitirFiltroDePesquisa="false"
    #natureza>
  </dft-multiselect>
</form>