import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptConfig } from './dialog.service';


@Component({
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss'],
})
export class DialogFormComponent {
  get dialog(): PromptConfig {
    return this.data;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptConfig,
    public dialogRef: MatDialogRef<DialogFormComponent>
  ) { }
}
