<aside class="main-sidebar" *ngIf="perfil$ | async as perfil" role="navigation">
  <section class="sidebar">
    <ul class="sidebar-menu" data-widget="tree">
      <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
                perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
                perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE"
        class="treeview"
        role="none">
        <a href="#" role="none" aria-haspopup="true" aria-controls="menu-tipificacao">
          <i aria-hidden="true" class="fa fa-check-circle"></i> <span>Tipificação</span>
        </a>
        <ul class="treeview-menu" id="menu-tipificacao">
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none">
            <a role="menuitem" routerLink="/tipificacao/orgao" routerLinkActive="active">
              Organizações
            </a>
          </li>
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none">
            <a role="menuitem" routerLink="/tipificacao/categoria-servico" routerLinkActive="active">
              Conjunto de Produto
            </a>
          </li>
          <li *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none">
            <a role="menuitem" routerLink="/tipificacao/unidade" routerLinkActive="active">
              Unidades
            </a>
          </li>
          <li *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE" role="none">
            <a role="menuitem" routerLink="/tipificacao/entrega" routerLinkActive="active">
              Produtos
            </a>
          </li>
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none">
            <a role="menuitem" routerLink="/tipificacao/cadeia-valor" routerLinkActive="active">
              Cadeia de Valores
            </a>
          </li>
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none">
            <a role="menuitem" routerLink="/tipificacao/indicador-produtividade" routerLinkActive="active" aria-label="Indicadores de produtividade">
              Indic. de Produtividade
            </a>
          </li>
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none">
            <a role="menuitem" routerLink="/tipificacao/indicador-pessoal" routerLinkActive="active" aria-label="Indicadores de pessoal">
              Indic. de Pessoal
            </a>
          </li>
        </ul>
      </li>

      <li
        *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE"
        class="treeview"
        role="none">
        <a href="#" role="none" aria-haspopup="true" aria-controls="menu-pessoa" aria-label="Indivíduos e perfis">
          <i aria-hidden="true" class="fa fa-address-card"></i> <span>Pessoa/Perfil</span>
        </a>
        <ul class="treeview-menu" id="menu-pessoa">
          <li role="none">
            <a role="menuitem" routerLink="/pessoa-perfil" routerLinkActive="active">
              Indivíduos
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" role="none">
        <a href="#" role="none" *ngIf="perfil.descricaoPerfil !== TipoPerfilEnum.GESTOR_ESTRATEGICO" aria-haspopup="true" aria-controls="menu-dimensionamento" aria-label="Menu de dimensionamento">
          <i aria-hidden="true" class="fa fa-clipboard"></i> <span>Ciclo de Produtividade</span>
        </a>
        <a href="#" role="none" *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO" aria-haspopup="true" aria-controls="menu-dimensionamento" aria-label="Menu de dimensionamento">
          <i aria-hidden="true" class="fa fa-clipboard"></i> <span>Resultados</span>
        </a>
        <ul class="treeview-menu" id="menu-dimensionamento">
          <li
            role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE">
            <a role="menuitem" routerLink="/dimensionamento/principal" routerLinkActive="active">
              Ciclos de Produtividade
            </a>
          </li>
          <li *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.USUARIO" role="none">
            <a role="menuitem" routerLink="/dimensionamento/quantificacao-esforcos" routerLinkActive="active">
              Quantificar Esforços
            </a>
          </li>
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO">
            <a role="menuitem" routerLink="/dimensionamento/consolidacao" routerLinkActive="active">
              Consolidações
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" role="none" *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL">
        <a href="#" role="none" aria-haspopup="true" aria-controls="menu-dimensionamento" aria-label="Menu de dimensionamento">
          <i aria-hidden="true" class="fa fa-usd"></i> <span>Centro de Custos</span>
        </a>
        <ul class="treeview-menu" id="menu-dimensionamento">
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL">
            <a role="menuitem" routerLink="/dimensionamento/centro-custo" routerLinkActive="active">
              Centro de custos
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO"
        class="treeview"
        role="none">
        <a href="#" role="none" aria-haspopup="true" aria-controls="menu-resultados">
          <i aria-hidden="true" class="fa fa-dashboard"></i> <span>Relatórios</span>
        </a>
        <ul class="treeview-menu" id="menu-resultados">
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO">
            <a role="menuitem" routerLink="/relatorios/relatorio-gerencial" routerLinkActive="active">
              Relatório Gerencial
            </a>
          </li>
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO">
            <a role="menuitem" routerLink="/relatorios/relatorio-perfil-usuario" routerLinkActive="active">
              Perfil por usuário
            </a>
          </li>
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO">
            <a role="menuitem" routerLink="/relatorios/relatorio-categoria-servico" routerLinkActive="active">
              Conjuntos de Produtos
            </a>
          </li>
          <li role="none"
            *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO">
            <a role="menuitem" routerLink="/relatorios/relatorio-descricao-unidade" routerLinkActive="active">
              Descrição das unidades
            </a>
          </li>
        </ul>
      </li>
      <li role="none" aria-haspopup="true" aria-controls="menu-configuracao"
        *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL"
        class="treeview">
        <a href="#" role="none">
          <i aria-hidden="true" class="fa fa-wrench"></i> <span>Configuração</span>
        </a>
        <ul class="treeview-menu" id="menu-configuracao">
          <li role="none">
            <a role="menuitem" routerLink="/configuracao/parametro" routerLinkActive="active">
              Parâmetros
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>
</aside>
