import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { ParametroEnum } from '@dft/shared/enums/parametro.enum';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { ParametroService } from '@dft/shared/services/parametro.service';
import { SpinnerService } from 'src/app/shared/dialog/spinner/spinner.service';
import { Orgao } from 'src/app/shared/models/orgao';
import { Unidade } from 'src/app/shared/models/unidade';
import { MensagemService } from 'src/app/shared/services/mensagem.service';
import { OrgaoService } from 'src/app/shared/services/orgao.service';
import { UnidadeService } from 'src/app/shared/services/unidade.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unidade-form.component.html',
  styleUrls: ['./unidade-form.component.scss'],
})
export class UnidadeFormComponent implements FormComponent, OnInit {
  form: FormGroup;
  readonly = false;

  listaDeOrgaos: Orgao[] = [];
  listaDeUnidadesOrgao: Unidade[] = [];
  listaDeCategorias: CategoriaServico[] = [];
  isOcultarImportacao = false;

  constructor(
    private unidadeService: UnidadeService,
    private orgaoService: OrgaoService,
    private mensagemService: MensagemService,
    private spinnerService: SpinnerService,
    private parametroService: ParametroService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      id: [''],
      orgao: [null, Validators.required],
      codigoReferencia: ['', null],
      descricao: ['', Validators.required],
      descricaoDetalhada: ['', null],
      sigla: ['', Validators.required],
      categoriaServicos: [[], Validators.required],
      unidadeSuperior: [null],
    });

    this.form.get('orgao').valueChanges.subscribe((orgao) => {
      this.atualizarDadosOrgaoSelecionado(orgao);
      this.atualizarListaUnidadeDoOrgao(orgao);
    });
  }

  ngOnInit(): void {
    this.orgaoService.listarOrgaos().subscribe((orgaos) => {
      this.listaDeOrgaos = orgaos;
      if (!this.form.value.id && this.listaDeOrgaos.length === 1) {
        this.form.get('orgao').setValue(this.listaDeOrgaos[0]);
      }
    });
    // Realiza a busca do parametro para ocultar a importação de unidades
    this.parametroService.buscarParametro(ParametroEnum.PARAMETRO_CONFIG_OCULTAR_IMPORTACAO_UNIDADE)
      .subscribe(parametro => {
        this.isOcultarImportacao = parametro.valor === 'false' ? false : true;
      });
  }

  public setFormValue(unidade: Unidade): void {
    if (
      unidade.unidadeSuperior &&
      unidade.orgao.id === unidade.unidadeSuperior.id
    ) {
      unidade.unidadeSuperior = null;
    }
    this.form.patchValue(unidade);

  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  private atualizarDadosOrgaoSelecionado(orgao?: Orgao): void {
    if (orgao) {
      // Atualiza a listagem de categorias de serviço da organização.
      this.listaDeCategorias = orgao.categoriaServicos;
    }
  }

  private atualizarListaUnidadeDoOrgao(orgao?: Orgao) {
    // Atualiza a listagem de unidades da organização.
    if (orgao) {
      this.orgaoService.buscarUnidadesFilhas(orgao.id).subscribe((unidades) => {
        this.listaDeUnidadesOrgao = unidades;

        // Remove da lista de unidades da organização a própria unidade
        const idUnidade = this.form.get('id').value;
        if (idUnidade) {
          this.listaDeUnidadesOrgao = unidades.filter(
            (unidade) => unidade.id !== idUnidade
          );
        }
      });
    }
  }

  buscarDadosPeloIdentificador(identificador: string) {
    const orgao = this.form.get('orgao').value;
    if (orgao) {
      const spinner = this.spinnerService.start();
      this.unidadeService.buscarDadosDaUnidadePorCodigo(orgao, identificador).subscribe(
        (unidade) => {

          if (unidade) {
            this.form.get('sigla').setValue(unidade.sigla);
            this.form.get('descricao').setValue(unidade.descricao);
          } else {
            this.mensagemService.alerta('Não foi encontrando a unidade com o identificador informado.');
          }
        },
        (err) => {
          this.mensagemService.erro(err);
          this.spinnerService.stop(spinner);
        },
        () => this.spinnerService.stop(spinner)
      );
    } else {
      this.mensagemService.erro('Para buscar a unidade é preciso antes selecionar a Organização.');
    }

  }
}
