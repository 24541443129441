<form [formGroup]="form" class="container">

    <input type="hidden" formControlName="id" />

    <mat-form-field appearance="outline">
      <mat-label for="orgao" attr.aria-hidden="true">Organização</mat-label>
      <input
        matInput
        name="orgao"
        aria-label="Orgão"
        [value]="form.controls['orgao']?.value?.descricao"
        disabled
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label for="nomeGratificacao" attr.aria-hidden="true">Gratificação</mat-label>
      <input
        matInput
        name="nomeGratificacao"
        formControlName="nome"
        aria-label="Gratificação"
        [value]="form.controls['nome']?.value"
        required
      />
    </mat-form-field>

  </form>
