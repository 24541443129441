import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntregaService } from '@dft/shared/services/entrega.service';
import { Entrega } from '@dft/shared/models/entrega';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mapa-unidade-produtos-form.component.html',
  styleUrls: ['./mapa-unidade-produtos-form.component.scss'],
})
export class MapaUnidadeProdutosFormComponent implements OnInit {
  form: FormGroup;
  produtosDisponiveis: Entrega[] = [];
  formValue = null;
  readonly = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly entregaService: EntregaService
  ) {
    this.form = this.formBuilder.group({
      novaEntrega: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.entregaService.listarEntregas().subscribe(
      (produtos) => {
        this.produtosDisponiveis = produtos;
      },
      (error) => {
        console.error('Erro ao carregar os produtos disponíveis:', error);
      }
    );

    if (this.formValue) {
      this.form.patchValue(this.formValue);
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
