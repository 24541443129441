import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'codigoFormatador' })
export class CodigoFormatadorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.length >= 4) {
      return `${value.substr(0, 4)}.${value.substr(4)}`;
    }
    return value;
  }
}
