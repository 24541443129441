<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Senha atual:</mat-label>
    <input
      matInput
      type="password"
      name="senhaAtual"
      formControlName="senhaAtual"
      required
    >
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Nova senha:</mat-label>
    <input
      matInput
      type="password"
      name="novaSenha"
      formControlName="novaSenha"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Confirmação da nova senha:</mat-label>
    <input
      matInput
      type="password"
      name="confirmacaoSenha"
      formControlName="confirmacaoSenha"
      required
    />
  </mat-form-field>
</form>
