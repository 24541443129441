<form [formGroup]="form">

  <mat-form-field appearance="outline">
    <mat-label>Ciclo de Produtividade</mat-label>
    <input
      matInput
      name="dimensionamento"
      [value]="dimensionamento.nome"
      readonly=true
    />
  </mat-form-field>

  <mat-list>
    <mat-list-item *ngFor="let validacao of validacoes">
      <mat-icon
        color="primary"
        class="material-icons-outlined"
        *ngIf="validacao.valido">
        check_circle_outline
      </mat-icon>
      <mat-icon
        color="warn"
        class="material-icons-outlined"
        *ngIf="!validacao.valido && validacao.impeditiva">
        error_outline
      </mat-icon>
      <mat-icon
        color="warn"
        class="material-icons-outlined"
        *ngIf="!validacao.valido && !validacao.impeditiva">
        warning
    </mat-icon>
      <span style="margin-left: 10px">
        {{ validacao.descricao }}
      </span>
    </mat-list-item>
  </mat-list>

  <div *ngIf="valido">
    <mat-divider></mat-divider>

    <br />

    <p>Deseja confirmar e enviar o dimensionamento para seu Gestor?</p>
  </div>

</form>
