import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IndicadorProdutividade } from '@dft/shared/models/indicador-produtividade';
import { IndicadorProdutividadeService } from '@dft/shared/services/indicador-produtividade.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mapa-unidade-indicadores-form.component.html',
  styleUrls: ['./mapa-unidade-indicadores-form.component.scss'],
})
export class MapaUnidadeIndicadoresFormComponent implements OnInit {
  form: FormGroup;
  indicadoresDisponiveis: IndicadorProdutividade[] = [];
  formValue = null;
  readonly = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly indicadorService: IndicadorProdutividadeService
  ) {
    this.form = this.formBuilder.group({
      novoIndicador: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.indicadorService.listarIndicadores().subscribe(
      (indicadores) => {
        this.indicadoresDisponiveis = indicadores;
      },
      (error) => {
        console.error('Erro ao carregar os indicadores disponíveis:', error);
      }
    );

    if (this.formValue) {
      this.form.patchValue(this.formValue);
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
