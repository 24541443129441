import { Pipe, PipeTransform } from '@angular/core';

import { STATUS_DIMENSIONAMENTO_MAP } from './../enums/status-dimensionamento.enum';
import { StatusDimensionamentoEnum } from 'src/app/shared/enums/status-dimensionamento.enum';

@Pipe({
  name: 'statusDimensionamento'
})
export class StatusDimensionamentoPipe implements PipeTransform {

  transform(status: StatusDimensionamentoEnum): string {
    return STATUS_DIMENSIONAMENTO_MAP.get(status);
  }

}
