import { AbstractControl } from '@angular/forms';

/* tslint:disable:cyclomatic-complexity */

export function cpfValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  const valid = testaCPF(control.value);
  return valid
    ? null
    : { invalidCpf: { valid: false, value: control.value } };
}

function testaCPF(strCPF) {

  let soma = 0;
  let resto;

  if (strCPF === '00000000000') {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }

  if (resto !== parseInt(strCPF.substring(9, 10)) ) {
    return false;
  }

  soma = 0;

  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) {
    resto = 0;
  }

  if (resto !== parseInt(strCPF.substring(10, 11) ) ) {
    return false;
  }

  return true;
}
