import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RelatorioCategoriaServico } from '@dft/modules/relatorios/relatorio-categoria-servico/relatorio-categoria-servico';
import { RelatorioDescricaoUnidade } from '@dft/modules/relatorios/relatorio-descricao-unidade/relatorio-descricao-unidade';
import { RelatorioGerencial } from '@dft/modules/relatorios/relatorio-gerencial/relatorio-gerencial';
import { RelatorioPerfisUsuario } from '@dft/modules/relatorios/relatorio-perfis-usuario/relatorio-perfis-usuario';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TipoMetodologiaEnum } from '../enums/metodologia.enum';
import { StatusDimensionamentoEnum } from '../enums/status-dimensionamento.enum';
import { Dimensionamento } from '../models/dimensionamento';
import { Entrega } from '../models/entrega';
import { IndicadorProdutividade } from '../models/indicador-produtividade';
import { Orgao } from '../models/orgao';
import { Pessoa } from '../models/pessoa';
import { Unidade } from '../models/unidade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UnidadeService {

  readonly apiUnidades = `${environment.urlApi}/unidades`;

  constructor(private http: HttpClient) { }

  public listarUnidades() {
    return this.http.get<Unidade[]>(`${this.apiUnidades}`, httpOptions);
  }

  public buscarUnidade(id: number): Observable<Unidade> {
    return this.http.get<Unidade>(`${this.apiUnidades}/${id}`, httpOptions);
  }

  public buscarOrgaoPorUnidade(unidade: Unidade) {
    return this.http.get<Orgao>(
      `${this.apiUnidades}/${unidade.id}/orgao`,
      httpOptions
    );
  }

  public inserirUnidade(unidade: Unidade) {
    return this.http.post<Unidade>(`${this.apiUnidades}`, unidade, httpOptions);
  }

  public deletarUnidade(unidade) {
    return this.http.delete<Unidade>(
      `${this.apiUnidades}/${unidade.id}`,
      unidade
    );
  }

  public editarUnidade(unidade: Unidade) {
    return this.http.put<Unidade>(
      `${this.apiUnidades}/${unidade.id}`,
      unidade,
      httpOptions
    );
  }

  public listarEntregas(idUnidade: number): Observable<Entrega[]> {
    return this.http.get<Entrega[]>(
      `${this.apiUnidades}/${idUnidade}/entregas`,
      httpOptions
    );
  }

  public buscarDadosDaUnidadePorCodigo(orgao: Orgao, codigo: string) {
    let params = new HttpParams();
    params = params.set('codigo', codigo);
    const options = { ...httpOptions, params };

    return this.http.get<Unidade>(
      `${this.apiUnidades}/${orgao.id}/integracao`,
      options
    );
  }

  public listarProdutividades(
    id: number
  ): Observable<IndicadorProdutividade[]> {
    return this.http.get<IndicadorProdutividade[]>(
      `${this.apiUnidades}/${id}/produtividades`,
      httpOptions
    );
  }

  public importarPessoasDaUnidade(unidade: Unidade) {
    return this.http.post<Unidade>(
      `${this.apiUnidades}/${unidade.id}/pessoas/importacao`,
      undefined,
      httpOptions
    );
  }

  public listarPessoasDaUnidade(unidade: Unidade): Observable<Pessoa[]> {
    return this.http.get<Pessoa[]>(
      `${this.apiUnidades}/${unidade.id}/pessoas`,
      httpOptions
    );
  }

  public listarDimensionamentosDaUnidade(
    unidade: Unidade, status?: StatusDimensionamentoEnum, tipo?: TipoMetodologiaEnum): Observable<Dimensionamento[]> {
    const queryParams = status ? tipo ? `?status=${status}&tipo=${tipo}` : `?status=${status}` : '';
    return this.http.get<Dimensionamento[]>(`${this.apiUnidades}/${unidade.id}/dimensionamentos${queryParams}`, httpOptions);
  }

  public gerarRelatorio(unidade: Unidade, ano: number): Observable<RelatorioGerencial[]> {
    return this.http.get<RelatorioGerencial[]>(
      `${this.apiUnidades}/${unidade.id}/relatorios-gerenciais/${ano}`,
      httpOptions
    );
  }

  public gerarRelatorioPerfisUsuario(unidade: Unidade): Observable<RelatorioPerfisUsuario[]> {
    return this.http.get<RelatorioPerfisUsuario[]>(
      `${this.apiUnidades}/${unidade.id}/relatorios-perfis-usuario`,
      httpOptions
    );
  }

  public gerarRelatorioCategoriaServico(unidade: Unidade, dim?: Dimensionamento): Observable<RelatorioCategoriaServico[]> {
    return this.http.get<RelatorioCategoriaServico[]>(
      dim ?
        `${this.apiUnidades}/${unidade.id}/relatorios-categoria-servico?idDimensionamento=${dim.id}` :
        `${this.apiUnidades}/${unidade.id}/relatorios-categoria-servico`,
      httpOptions
    );
  }

  public gerarRelatorioDescricaoUnidade(unidade: Unidade): Observable<RelatorioDescricaoUnidade[]> {
    return this.http.get<RelatorioDescricaoUnidade[]>(
      `${this.apiUnidades}/${unidade.id}/relatorio-descricao-unidade`,
      httpOptions
    );
  }

}
