export enum StatusDimensionamentoEnum {
  ANALISADO = 'ANALISADO',
  DIMENSIONADO = 'DIMENSIONADO',
  DEVOLVIDO_AJUSTES = 'DEVOLVIDO_AJUSTES',
  EM_ANALISE = 'EM_ANALISE',
  EM_ELABORACAO = 'EM_ELABORACAO',
  INICIAL = 'INICIAL',
}

export const STATUS_DIMENSIONAMENTO_MAP = new Map<
  StatusDimensionamentoEnum,
  string
>([
  [StatusDimensionamentoEnum.ANALISADO, 'Validado'],
  [StatusDimensionamentoEnum.DIMENSIONADO, 'Calculado'],
  [StatusDimensionamentoEnum.DEVOLVIDO_AJUSTES, 'Devolvido para ajustes'],
  [StatusDimensionamentoEnum.EM_ANALISE, 'Em validação'],
  [StatusDimensionamentoEnum.INICIAL, 'Inicial'],
  [StatusDimensionamentoEnum.EM_ELABORACAO, 'Em elaboração'],
]);
