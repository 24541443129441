import { Lista } from '../models/lista';

export enum InteracaoEnum {
  IND = 'IND',
  DEP = 'DEP',
  INT = 'INT'
}

export const INTERACAO_MAP: Lista[] = [{
  id: InteracaoEnum.IND,
  descricao: 'Independência',
  detalhamento: 'A entrega pode ser realizada por um único trabalhador, sem necessidade de que este, para executá-la ou finalizá-la, tenha de estabelecer relações técnicas de trabalho com outros membros da órgão.'
}, {
  id: InteracaoEnum.DEP,
  descricao: 'Dependência',
  detalhamento: 'A entrega só pode ser elaborada por dois ou mais trabalhadores, os quais executam o que lhes compete sequencialmente, não sendo necessário estabelecer relações técnicas de trabalho de modo a juntos executá-la ou finalizá-la.'
}, {
  id: InteracaoEnum.INT,
  descricao: 'Interdependência',
  detalhamento: 'A entrega só pode ser elaborada por dois ou mais trabalhadores, os quais necessariamente devem trabalhar em conjunto a fim de executá-la ou concluí-la.'
}];
