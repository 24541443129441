<mat-toolbar color="primary">
  <mat-toolbar-row align="end">

    <div class="container">
      <!-- Left-aligned div -->
      <div class="left-div">
        <button (click)="drawer.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
      </button>

      <span><img src="../../../assets/logo_workforce_tollbar.png" alt="Logo" class="logo-image"></span>
      <span *ngIf="isSmallScreen == false">WORKFORCE SIZING</span>

      </div>

      <!-- Right-aligned div -->
      <div class="right-div">
        <div *ngIf="isUsuarioAutenticado() | async" class="alinhamento-direita">
          <ng-container *ngIf="{usuarioLogado: usuarioLogado$ | async, perfil: perfil$ | async} as dadosUsuario">

              <button *ngIf="isSmallScreen == false"
              (click)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
              (keypress)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
              mat-button>
              {{ dadosUsuario.usuarioLogado.nome }} | {{ dadosUsuario.perfil.descricaoPerfilUnidade }}
              </button>


              <button *ngIf="isSmallScreen == true"
                class="botao-user-pequeno"
                (click)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
                (keypress)="visualizarInfosUsuario(dadosUsuario.usuarioLogado, dadosUsuario.perfil)"
              >
                <i class="material-icons">person</i>
              </button>


          </ng-container>
        </div>
      </div>
    </div>

  </mat-toolbar-row>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer #drawer opened="true" mode="side" position="start">
    <mat-nav-list *ngIf="perfil$ | async as perfil"dense>


      <mat-expansion-panel *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">assignment</mat-icon> <span class="texto-menu-10"> ESPECIFICAÇÃO:</span> Estratégico</button>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" mat-list-item routerLink="/tipificacao/cadeia-valor">
            <span class="span-item">Cadeia de Valor Produtivo</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-expansion-panel *ngIf="
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">work</mat-icon> <span class="texto-menu-10"> ESPECIFICAÇÃO:</span> Organizacional</button>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" mat-list-item routerLink="/tipificacao/orgao">
            <span class="span-item">Organização</span>
          </a>
          <a *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item routerLink="/tipificacao/unidade">
            <span class="span-item">Unidade Produtiva</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-expansion-panel *ngIf="
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">line_weight</mat-icon> <span class="texto-menu-10"> ESPECIFICAÇÃO:</span> Produção</button>
        </mat-expansion-panel-header>
          <mat-expansion-panel *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE" role="none" [class.mat-elevation-z0]="true" dense>
            <mat-expansion-panel-header>
                <button mat-button>Modelo Típico</button>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a *ngIf="
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
              perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE" role="none" mat-list-item routerLink="/tipificacao/entrega">
                <span class="span-item">Produtos</span>
              </a>
              <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" mat-list-item routerLink="/tipificacao/categoria-servico">
                <span class="span-item">Conjunto de produtos</span>
              </a>
            </mat-nav-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
        perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" [class.mat-elevation-z0]="true" dense>
          <mat-expansion-panel-header>
              <button mat-button>Modelo Atípico</button>
          </mat-expansion-panel-header>
          <mat-nav-list dense>
            <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item routerLink="/tipificacao/indicador-produtividade">
              <span class="span-item">Marcos de Produtividade</span>
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
          <mat-nav-list dense>
            <a role="none" mat-list-item routerLink="/tipificacao/mapa-unidade">
              <span class="span-item">Mapa da Unidade</span>
            </a>
          </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-expansion-panel *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">supervised_user_circle</mat-icon> <span class="texto-menu-10"> ESPECIFICAÇÃO:</span> Registros de Pessoal</button>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL" role="none" mat-list-item routerLink="/tipificacao/indicador-pessoal">
            <span class="span-item">Índices de pessoal </span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-list-item *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE">
        <button routerLink="/pessoa-perfil" mat-button><mat-icon class="espaco-direita">account_box</mat-icon> Indivíduo/Acesso</button>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-expansion-panel *ngIf="
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE ||
      perfil.descricaoPerfil === TipoPerfilEnum.USUARIO" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">check_circle</mat-icon> Produtividade</button>
        </mat-expansion-panel-header>
          <mat-nav-list dense>
            <a *ngIf="
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE" role="none" mat-list-item routerLink="/dimensionamento/principal">
              <span class="span-item">Ciclos de Produtividade</span>
            </a>
            <a *ngIf="
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ESTRATEGICO ||
            perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item routerLink="/dimensionamento/consolidacao">
              <span class="span-item">Consolidações</span>
            </a>
            <a *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.USUARIO" role="none" mat-list-item routerLink="/dimensionamento/quantificacao-esforcos">
              <span class="span-item">Quantificar Esforços</span>
            </a>
          </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-expansion-panel *ngIf="
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
      perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">assignment</mat-icon> Relatórios</button>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item
          routerLink="/relatorios/relatorio-gerencial">
            <span class="span-item">Controle</span>
          </a>
        </mat-nav-list>
        <mat-nav-list dense>
          <a *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item
          routerLink="/relatorios/relatorio-perfil-usuario">
            <span class="span-item">Perfil por usuário</span>
          </a>
        </mat-nav-list>
        <mat-nav-list dense>
          <a *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item
          routerLink="/relatorios/relatorio-categoria-servico">
            <span class="span-item">Conjuntos de Produtos</span>
          </a>
        </mat-nav-list>
        <mat-nav-list dense>
          <a *ngIf="
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL ||
          perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_ORGAO" role="none" mat-list-item
          routerLink="/relatorios/relatorio-descricao-unidade">
            <span class="span-item">Descrição das Unidades Produtivas</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-divider></mat-divider>

      <mat-list-item *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL">
        <button routerLink="/dimensionamento/centro-custo" mat-button><mat-icon class="espaco-direita">monetization_on</mat-icon> Custeio Geral</button>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-list-item *ngIf="perfil.descricaoPerfil === TipoPerfilEnum.GESTOR_GERAL">
        <button routerLink="/configuracao/parametro" mat-button><mat-icon class="espaco-direita">settings</mat-icon> Parametrização</button>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
        <mat-expansion-panel-header>
            <button mat-button><mat-icon class="espaco-direita">book</mat-icon> Tutoriais</button>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a role="none" mat-list-item routerLink="/tutorial/#">
            <span class="span-item">FAQ</span>
          </a>
        </mat-nav-list>
        <mat-nav-list dense>
          <a role="none" mat-list-item routerLink="/tutorial/#">
            <span class="span-item">Manual do Sistema</span>
          </a>
        </mat-nav-list>
        <mat-nav-list dense>
          <a role="none" mat-list-item routerLink="/tutorial/#">
            <span class="span-item">Vídeos</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>



    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>
    <div style="height: calc(100vh - 120px); display: flex; flex-direction: column; margin-top: 10px; margin-right: 5px;">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


