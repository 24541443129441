<form [formGroup]="form">
  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">
      Nome
    </mat-label>
    <input
      aria-label="Nome do parâmetro"
      matInput
      name="nome"
      formControlName="nome" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">
      Descrição
    </mat-label>
    <input
      aria-label="Descrição do parâmetro"
      matInput
      name="descricao"
      formControlName="descricao"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">
      Valor
    </mat-label>
    <input
      aria-label="Valor do Parâmetro"
      matInput
      name="valor"
      placeholder="Ex.: webservice.receita"
      formControlName="valor"
      [disabled]="readonly"
      required
      maxlength="100"
    />
  </mat-form-field>
</form>
