<div role="alertdialog">
  <h2 mat-dialog-title>
    {{ dialog.title }}
  </h2>
  <mat-dialog-content class="content-container">
    <div [innerHtml]="dialog.content"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      *ngIf="dialog.ok"
      mat-stroked-button
      color="primary"
      (click)="dialogRef.close(true)"
    >
      <i class="material-icons-outlined" aria-hidden="true">check_circle_outline</i>
      {{ dialog.ok }}
    </button>
    <button mat-stroked-button (click)="dialogRef.close(false)" color="warn">
      <i class="material-icons-outlined" aria-hidden="true">cancel</i>
      {{ dialog.close }}
    </button>
  </mat-dialog-actions>
</div>