export enum StatusIndicadorProdutividadeEnum {
  ATIVO = 'ATIVO',
  INATIVO = 'INATIVO',
  NAO_VALIDADO = 'NAO_VALIDADO'
}

export const STATUS_INDICADOR_MAP = [
  { id: StatusIndicadorProdutividadeEnum.ATIVO, descricao: 'Ativo' },
  { id: StatusIndicadorProdutividadeEnum.INATIVO, descricao: 'Inativo' },
  { id: StatusIndicadorProdutividadeEnum.NAO_VALIDADO, descricao: 'Não Validado' },
];
