import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class MensagemService {

  private readonly SNACKBAR_DURATION = 6000;
  private readonly SNACKBAR_DURATION_WARN = 10000;
  private readonly CLOSE_LABEL = 'FECHAR';

  constructor(private snackbar: MatSnackBar) { }

  info(message: string, duration = this.SNACKBAR_DURATION, closeLabel = this.CLOSE_LABEL) {
    this.snackbar.open(message, closeLabel, {
      duration,
      panelClass: ['custom-success'],
    });
  }

  erro(message: HttpErrorResponse | string, duration = this.SNACKBAR_DURATION, closeLabel = this.CLOSE_LABEL) {
    const msgErro = this.getMensagemErro(message);
    this.snackbar.open(msgErro, closeLabel, {
      duration,
      panelClass: ['custom-error'],
    });
  }

  alerta(message: string, duration = this.SNACKBAR_DURATION_WARN, closeLabel = this.CLOSE_LABEL) {
    this.snackbar.open(message, closeLabel, {
      duration,
      panelClass: ['custom-warn'],
    });
  }

  private getMensagemErro(message: HttpErrorResponse | string | object): string {
    let msg: any = message;
    if (message instanceof HttpErrorResponse) {
      msg = message.error.mensagem;
      if (!msg) {
        msg = message.error;
      }
    } else if (message instanceof Object) {
        msg = msg.codigoErro + ' - ' + msg.mensagem;
    }
    return msg;
  }
}
