import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Cargo } from '../models/cargo';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class CargoService {

  constructor(private http: HttpClient) {}

  public listarCargos(idOrgao: number): Observable<Cargo[]> {
    return this.http.get<Cargo[]>(`${environment.urlApi}/orgaos/${idOrgao}/cargos`, httpOptions);
  }

  public criarCargo(cargo: Cargo) {
    return this.http.post<Cargo>(
      `${environment.urlApi}/orgaos/${cargo.orgao.id}/cargos`,
      cargo,
      httpOptions);
  }

  public editarCargo(cargo: Cargo) {
    return this.http.put<Cargo>(
      `${environment.urlApi}/orgaos/${cargo.orgao.id}/cargos/${cargo.id}`,
      cargo,
      httpOptions);
  }

  public excluirCargo(cargo: Cargo) {
    return this.http.delete<Cargo>(
      `${environment.urlApi}/orgaos/${cargo.orgao.id}/cargos/${cargo.id}`
    );
  }
}
