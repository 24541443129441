import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IndicadorProdutividade } from '../models/indicador-produtividade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class IndicadorProdutividadeService {
  apiIndicadoresProdutividade = `${environment.urlApi}/produtividades`;

  constructor(private http: HttpClient) {}

  public listarIndicadores() {
    return this.http.get<IndicadorProdutividade[]>(`${this.apiIndicadoresProdutividade}`, httpOptions);
  }

  public indicadorProdutividade(id: number) {
    return this.http.get<IndicadorProdutividade>(`${this.apiIndicadoresProdutividade}/${id}`, httpOptions);
  }

  public inserirIndicadorProdutividade(indicadorProdutividade: IndicadorProdutividade) {
    return this.http.post<IndicadorProdutividade>(`${this.apiIndicadoresProdutividade}`, indicadorProdutividade, httpOptions);
  }

  public deletarIndicadorProdutividade(indicadorProdutividade: IndicadorProdutividade) {
    return this.http.delete<IndicadorProdutividade>(
      `${this.apiIndicadoresProdutividade}/${indicadorProdutividade.id}`,
      httpOptions
    );
  }

  public editarIndicadorProdutividade(indicadorProdutividade: IndicadorProdutividade) {
    return this.http.put<IndicadorProdutividade>(
      `${this.apiIndicadoresProdutividade}/${indicadorProdutividade.id}`,
      indicadorProdutividade,
      httpOptions
    );
  }

  public buscarSugestaoCodigoIndicadorProdutividade(idCategoria: number): Observable<string> {
    return this.http.get(
      `${this.apiIndicadoresProdutividade}/${idCategoria.toString()}/sugestao-codigo`, { responseType: 'text' });
  }
}
