import {
  ChangeDetectionStrategy,
  Component, OnInit, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { DimensionamentoSimples } from '@dft/shared/models/dimensionamento-simples';
import { IndiceTempoProdutivo } from '@dft/shared/models/indice-tempo-produtivo';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  StatusDimensionamentoEnum,
  STATUS_DIMENSIONAMENTO_MAP
} from 'src/app/shared/enums/status-dimensionamento.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'dimensionamento-calculo-todos-form.component.html',
  styleUrls: ['./dimensionamento-calculo-todos-form.component.scss'],
})
export class DimensionamentoCalculoTodosFormComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  form: FormGroup;
  formValue = null;
  readonly = false;

  dataSource: MatTableDataSource<DimensionamentoSimples>;
  displayedColumns: string[] = ['nome'];
  resultsLength = 0;
  totalDimensionamentosParaCalculo = 0;
  isCalculando: boolean;
  isCalculoFinalizado: boolean;
  valorX$: Observable<number>;
  private readonly TAMANHO_LOTE_CALCULO = 5;

  totalJaProcessado = new BehaviorSubject(0);
  porcentagemJaProcessado = new BehaviorSubject(0);

  TipoMetodologiaEnum: typeof TipoMetodologiaEnum = TipoMetodologiaEnum;
  StatusDimensionamentoEnum: typeof StatusDimensionamentoEnum = StatusDimensionamentoEnum;

  // utillizado no template para conversão do status
  statusDimensionamentoMap: typeof STATUS_DIMENSIONAMENTO_MAP = STATUS_DIMENSIONAMENTO_MAP;

  constructor(
    private _formBuilder: FormBuilder,
    private _mensagemService: MensagemService) {
    this.form = this._formBuilder.group({
      indice: this._formBuilder.control(0.0),
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<DimensionamentoSimples>(
      this.formValue.dimensionamentosParaCalculo
    );
    this.totalDimensionamentosParaCalculo = this.dataSource.data.length;
    this.configurarTabela();
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  private configurarTabela() {
    this.dataSource.paginator = this.paginator;
  }

  public montarDescricaoLabel(dimensionamento: DimensionamentoSimples): string {
    const texto = 'Dimensionamento';
    if (!dimensionamento.descricao) {
      return `${texto} sem observação. Nome: ${dimensionamento.nome}.`;
    }
    return `${texto} com observação: ${dimensionamento.descricao}.`;
  }

  calculaTodosDimensionamentos() {
    const indiceTempoProdutivo = new IndiceTempoProdutivo();
    indiceTempoProdutivo.valor = this.form.value.indice;

    if (!indiceTempoProdutivo.valor || indiceTempoProdutivo.valor <= 0) {
      this._mensagemService.erro(
        `O indicador ITP deve ter o valor maior que zero!`
      );
    } else {
      this.processarCalculoDimensionamentosEmLote(indiceTempoProdutivo, this.dataSource.data);
    }
  }

  async processarCalculoDimensionamentosEmLote(indiceTempoProdutivo: IndiceTempoProdutivo,
    dimensionamentosParaCalculo: DimensionamentoSimples[]) {
    const dimensionamentosPorLote = [];
    this.isCalculando = true;

    for (let i = 0; i < dimensionamentosParaCalculo.length; i += this.TAMANHO_LOTE_CALCULO) {
      dimensionamentosPorLote.push(dimensionamentosParaCalculo.slice(i, this.TAMANHO_LOTE_CALCULO + i));
    }

    let totalProcessado = 0;
    let porcentagemProcessamento = 0;
    for (const loteDimensionamentos of dimensionamentosPorLote) {
      totalProcessado += loteDimensionamentos.length;
      porcentagemProcessamento = Math.round((totalProcessado / dimensionamentosParaCalculo.length) * 100);

      this.totalJaProcessado.next(totalProcessado);
      this.porcentagemJaProcessado.next(porcentagemProcessamento);
    }

    this._mensagemService.info(
      `Dimensionamentos selecionados foram cálculados com sucesso!`
    );
    this.isCalculando = false;
    this.isCalculoFinalizado = true;
  }
}
