import { TipoOrgaoEnum } from '../enums/tipo-orgao.enum';
import { TipoPoderEnum } from '../enums/tipo-poder.enum';
import { CategoriaServico } from './categoria-servico';
import { Orgao } from './orgao';

export class Unidade {
  public bindName?: string;

  public id: number;
  public descricao: string;
  public descricaoDetalhada: string;
  public sigla?: string;
  public categoriaServicos?: CategoriaServico[];
  public unidadeSuperior?: Unidade;
  public orgao?: Unidade;
  public ativo?: boolean;
  public codigoReferencia?: string;
  public gestorOrgaoSemVisibilidade?: boolean;
  public orgaoClone: Orgao;
  public tipoPoder: TipoPoderEnum;
  public tipoOrgao: TipoOrgaoEnum;

  constructor(
    id?: number,
    descricao?: string,
    sigla?: string,
    descricaoDetalhada?: string,
    categoriaServicos?: CategoriaServico[],
    unidadeSuperior?: Unidade,
    orgao?: Unidade,
    ativo?: boolean,
    codigoReferencia?: string,
    gestorOrgaoSemVisibilidade?: boolean,
    tipoPoder?: TipoPoderEnum,
    tipoOrgao?: TipoOrgaoEnum
  ) {
    this.id = id;
    this.descricao = descricao;
    this.sigla = sigla;
    this.descricaoDetalhada = descricaoDetalhada;
    this.categoriaServicos = categoriaServicos;
    this.unidadeSuperior = unidadeSuperior;
    this.orgao = orgao;
    this.ativo = ativo;
    this.codigoReferencia = codigoReferencia;
    this.gestorOrgaoSemVisibilidade = gestorOrgaoSemVisibilidade;
    this.tipoPoder = tipoPoder;
    this.tipoOrgao = tipoOrgao;
  }

  public static montarBindName(orgaos: Unidade[]): Unidade[] {
    orgaos.forEach((orgao) => {
      orgao.bindName = `${orgao.sigla} - ${orgao.descricao}`;
    });
    return orgaos;
  }
}
