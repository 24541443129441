import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import * as Color from 'color';

/**
 * Aplica um gradiente de cores a uma lista de elementos, utilizando uma cor base e um gradiente de clareamento (0.0 ~ 1.0)
 *
 */
@Directive({ selector: '[dftListaCorGradiente]' })
export class ListaCorGradienteDirective implements AfterViewInit, OnChanges {
  @Input() cor: string;
  @Input() gradClareamento: number;
  @Input() ativo = false;

  corInicial: string;
  previamenteColorido = false;

  constructor(private elemento: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    if (this.elemento && this.elemento.nativeElement) {
      this.corInicial = this.elemento.nativeElement.style.backgroundColor;
    }
  }

  ngOnChanges(): void {
    if (this.deveColorir()) {
      this.previamenteColorido = true;
      this.processarInputs();
    }
    if (!this.ativo && this.previamenteColorido && this.elemento && this.elemento.nativeElement) {
      this.previamenteColorido = false;
      this.elemento.nativeElement.style.backgroundColor = this.corInicial;
    }
  }

  private deveColorir(): boolean {
    return this.ativo && this.cor !== undefined && this.cor !== '' && this.gradClareamento !== undefined;
  }

  private processarInputs(): void {
    const corCss = Color(this.cor);
    this.atribuirCorElemento(corCss.lighten(this.gradClareamento));
  }

  private atribuirCorElemento(corCss: Color): void {
    if (this.elemento && this.elemento.nativeElement) {
      this.elemento.nativeElement.style.backgroundColor = corCss.hex();
    }
  }
}
