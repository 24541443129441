<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Conjunto de Produto *</mat-label>
    <mat-select formControlName="idConjunto" [disabled]="readonly">
      <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">
        {{ categoria.descricao }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('idConjunto').hasError('required')">
      É necessário selecionar uma categoria.
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Atividade
    </mat-label>
    <input aria-label="Atividade" matInput name="atividade" formControlName="atividade" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Nome
    </mat-label>
    <input aria-label="Nome" matInput name="nome" formControlName="nome" required />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Fluxo de Trabalho
    </mat-label>
    <textarea aria-label="Fluxo" matInput name="fluxoTrabalho" formControlName="fluxoTrabalho" required></textarea>
  </mat-form-field>
  <mat-label>
    Status:
  </mat-label>
  <dft-multiselect formControlName="status" [listaDeOpcoes]="StatusEntrega" placeholder="Selecione o status"
    [desabilitado]="readonly" [delineado]="true" [selecaoUnica]="true" permitirFiltroDePesquisa="false"
    ordenacao="descricao" [desabilitado]="(isGestorGeral$ | async) === false" #status>
  </dft-multiselect>
</form>
