import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Escolaridade } from '../models/escolaridade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class EscolaridadeService {

  constructor(private http: HttpClient) {}

  public listarEscolaridades(idOrgao: number): Observable<Escolaridade[]> {
    return this.http.get<Escolaridade[]>(`${environment.urlApi}/orgaos/${idOrgao}/escolaridades`, httpOptions);
  }

  public criarEscolaridades(escolaridade: Escolaridade) {
    return this.http.post<Escolaridade>(
      `${environment.urlApi}/orgaos/${escolaridade.orgao.id}/escolaridades`,
      escolaridade,
      httpOptions);
  }

  public editarEscolaridades(escolaridade: Escolaridade) {
    return this.http.put<Escolaridade>(
      `${environment.urlApi}/orgaos/${escolaridade.orgao.id}/escolaridades/${escolaridade.id}`,
      escolaridade,
      httpOptions);
  }

  public excluirEscolaridades(escolaridade: Escolaridade) {
    return this.http.delete<Escolaridade>(
      `${environment.urlApi}/orgaos/${escolaridade.orgao.id}/escolaridades/${escolaridade.id}`
    );
  }
}
