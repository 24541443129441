import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Orgao } from '@dft/shared/models/orgao';
import { SituacaoFuncional } from '@dft/shared/models/situacao-funcional';
import { SituacaoFuncionalService } from '@dft/shared/services/situacao-funcional.service';

@Component({
  templateUrl: './orgao-sit-funcional-form.component.html',
  styleUrls: ['./orgao-sit-funcional-form.component.scss'],
})
export class OrgaoSitFuncionalFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  orgao: Orgao;
  listaDeSitFuncionais: SituacaoFuncional[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _sitFuncionalService: SituacaoFuncionalService,
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      orgao: this._formBuilder.control(''),
      nome: this._formBuilder.control(null),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.orgao = this.formValue.orgao;
      if (this.formValue) {
        let id = 0;
        let nome = '';
        if (this.formValue.sitFuncional) {
          id = this.formValue.sitFuncional.id;
          nome = this.formValue.sitFuncional.nome;
        }
        this.form.setValue({
          id,
          orgao: this.formValue.orgao,
          nome,
        });
      }

      this._sitFuncionalService
        .listarSituacoesFuncionais(this.orgao.id)
        .subscribe((result) => {
          this.listaDeSitFuncionais = result;
        });
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
