<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">
      Descrição
    </mat-label>
    <textarea
      matInput
      name="descricao"
      formControlName="descricao"
      rows="4">
    </textarea>
    
  </mat-form-field>
</form>