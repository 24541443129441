<form [formGroup]="form">
  <input type="hidden" formControlName="id" />

  <mat-label>Órgão:</mat-label>
  <dft-multiselect formControlName="orgao" [listaDeOpcoes]="orgaos" placeholder="Selecione o órgão"
    [desabilitado]="readonly" [delineado]="true" [selecaoUnica]="true" permitirFiltroDePesquisa="false"
    ordenacao="descricao">
  </dft-multiselect>
  <br />
  <mat-label>Unidade:</mat-label>
  <dft-multiselect formControlName="unidade" [listaDeOpcoes]="unidades" placeholder="Selecione a unidade"
    [desabilitado]="!form.get('orgao').value" [delineado]="true" [selecaoUnica]="true"
    permitirFiltroDePesquisa="false" ordenacao="descricao">
  </dft-multiselect>
  <br />
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Nome do Mapa da Unidade
    </mat-label>
    <input aria-label="Nome do Mapa da Unidade" matInput name="nome" placeholder="Ex.: Mapa da Unidade"
      formControlName="nome" [readonly]="readonly" maxlength="200" required />
  </mat-form-field>

  <mat-label>Status:</mat-label>
  <dft-multiselect formControlName="status" [listaDeOpcoes]="statusMapaUnidade" placeholder="Selecione o status"
    [desabilitado]="readonly" [delineado]="true" [selecaoUnica]="true" permitirFiltroDePesquisa="false"
    ordenacao="descricao">
  </dft-multiselect>
</form>