export enum FormacaoEnum {
  M = 'M',
  S = 'S',
  ME = 'ME',
  SE = 'SE'
}

export const FORMACAO_MAP = [
  { id: FormacaoEnum.M, descricao: 'Nível Médio Generalista' },
  { id: FormacaoEnum.S, descricao: 'Nível Superior Generalista' },
  { id: FormacaoEnum.ME, descricao: 'Nível Médio Especialista' },
  { id: FormacaoEnum.SE, descricao: 'Nível Superior Especialista' }
];
