<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Dados Gerais">
    <dft-entrega-dados-gerais [form]="form" [entrega]="formValue"></dft-entrega-dados-gerais>
  </mat-tab>
  
  <mat-tab label="Formação">
    <dft-entrega-formacao [form]="form" [entrega]="formValue"></dft-entrega-formacao>
  </mat-tab>
  
  <mat-tab label="Áreas de Conhecimento">
    <dft-entrega-areas-conhecimento [form]="form" [entrega]="formValue"></dft-entrega-areas-conhecimento>
  </mat-tab>
</mat-tab-group>
