<div class="info-message">
  <p>
  <span class="texto-menu-10">AVISO:</span>
  Caso o produto não esteja disponível, contate a unidade responsável para inclusão dessa atividade na sua unidade.
  </p>
</div>
<div class="info-message">
  <p>
  <span class="texto-menu-10-dica">DICA:</span>
  Caso tenha dúvida sobre o produto, clique no ícone de informação para descrição completa da atividade.
  </p>
</div>
<mat-card class="entregas-container">
  <mat-card-content>
    <div class="row">
      <div class="col-md-5">
        <dft-multiselect
          [delineado]="false"
          [selecaoUnica]="true"
          [listaDeOpcoes]="listaDeCategoriasServico"
          ngModel
          (ngModelChange)="filtrarPorCategoriaServico($event)"
          placeholder="Conjuntos de Produtos"
          permitirFiltroDePesquisa="true">
        </dft-multiselect>
      </div>
      <div class="col-md-7">
        <mat-form-field>
          <input
            matInput
            placeholder="Filtrar registros"
            aria-label="Digite para filtrar os registros"
            [(ngModel)]="filtro"
            (keyup)="filtrar()"
          />
          <button mat-button matSuffix mat-icon-button aria-label="Limpar filtro" (click)="limparFiltro()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <form [formGroup]="form">
      <table
        mat-table
        [dataSource]="datasource"
        class="table table-bordered table-hover largura"
        matSort
        matSortActive="nome"
        matSortDisableClear
        matSortDirection="asc">

      <ng-container matColumnDef="entrega">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="nome">
          PRODUTO
        </th>

        <td mat-cell *matCellDef="let row" [formGroup]="row" role="text"
          attr.aria-label="Marque para selecionar {{ row.get('entrega').value.nome }} para o Ciclo de Produtividade">
          <mat-checkbox
            formControlName="selecionado" class="wrap-mat-checkbox">
          </mat-checkbox>
          <a mat-icon-button
            role="button"
            aria-roledescription="Botão de ação"
            color="primary"
            id="btn-fluxo-de-trabalho"
            (click)="exibeEntrega(row.get('entrega').value)"
            (keypress)="exibeEntrega(row.get('entrega').value)"
            aria-label="Veja os detalhes dessa entrega, incluindo o fluxo de trabalho.">

            <mat-icon class="material-icons-outlined md-18"
              matTooltip="Veja os detalhes dessa entrega, incluindo o fluxo de trabalho.">
              info
            </mat-icon>
          </a>
          {{ row.get('entrega').value.nome }}
        </td>
      </ng-container>
      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="codigo">
          CÓDIGO
        </th>
        <td mat-cell *matCellDef="let row" role="text"
          attr.aria-label="Código da entrega: {{ row.get('entrega').value.codigo }}">
          {{ row.get('entrega').value.codigo | codigoFormatador }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
    [length]="resultsLength"
    [pageSize]="10"
    [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
  </form>

</mat-card-content>
</mat-card>
<br />
