import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { TipoAutenticador } from '@dft/shared/enums/tipo-autenticador.enum';

export function initializer(keycloak: KeycloakService): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        /*
         * Se o ambiente não estiver configurado para utilizar o Keycloak,
         * apenas resolve o Promise.
         */
        if (environment.autenticacao.tipo !== TipoAutenticador.KEYCLOAK) {
          resolve();
          return;
        }

        await keycloak.init({
          config: {
            realm: environment.autenticacao.realm,
            url: environment.autenticacao.url,
            clientId: environment.autenticacao.clientId,
            /**
             * A partir da versão 8.0.0 do Keycloak, a opção de passagem de credenciais foi removida
             * do cliente Javascript, por ser considerada uma falha de segurança manter informações
             * secretas em apps client-side.
             *
             * https://www.keycloak.org/docs/latest/release_notes/index.html#credentials-support-removed-from-the-javascript-adapter
             * https://www.keycloak.org/docs/latest/upgrading/index.html#client-credentials-in-the-javascript-adapter
             *
             * credentials: {
             *  secret: environment.autenticacao.clientSecret,
             * },
             */
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
          },
          bearerExcludedUrls: [],
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
