<h2 mat-dialog-title>Informações do Usuário</h2>
<mat-dialog-content style="padding-bottom: 20px;">
  <p><strong>Nome:</strong> {{ data.pessoa.nome }}</p>
  <p><strong>CPF:</strong> {{ data.pessoa.cpf }}</p>
  <p><strong>Organização:</strong> {{ data.perfil.descricaoOrgao }}</p>
  <!-- As informações abaixo são do perfil selecionado -->
  <p><strong>Unidade Produtiva:</strong> {{ data.perfil.descricaoUnidade }}</p>
  <p><strong>Perfil Atual:</strong> {{ data.perfil.descricaoPerfilUnidade }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" routerLink="/gestao-perfil/" mat-dialog-close>
    <i class="material-icons-outlined" aria-hidden="true">assignment_ind</i>
    Perfis
  </button>
  <button mat-stroked-button color="primary" mat-dialog-close="senha" *ngIf="data.authLocal">
    <i class="material-icons-outlined" aria-hidden="true">vpn_key</i>
    Senha
  </button>
  <button mat-raised-button color="warn" mat-dialog-close="logout">
    <i class="material-icons-outlined">exit_to_app</i>
    Sair
  </button>
</mat-dialog-actions>
