import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Afastamento } from '@dft/shared/models/afastamento';
import { Orgao } from '@dft/shared/models/orgao';
import { OrgaoService } from '@dft/shared/services/orgao.service';

@Component({
  templateUrl: './orgao-afastamento-form.component.html',
  styleUrls: ['./orgao-afastamento-form.component.scss'],
})
export class OrgaoAfastamentoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  orgao: Orgao;
  listaDeAfastamentos: Afastamento[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _orgaoService: OrgaoService
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      orgao: this._formBuilder.control(''),
      nomeAfastamento: this._formBuilder.control(null),
      codigoAfastamento: this._formBuilder.control(null),
      calculo: this._formBuilder.control(''),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.orgao = this.formValue.orgao;
      if (this.formValue) {
        let idAfastamento = 0;
        let nomeAfastamento = '';
        let codigoAfastamento = '';
        let calculo = true;
        if (this.formValue.afastamento) {
          idAfastamento = this.formValue.afastamento.id;
          nomeAfastamento = this.formValue.afastamento.nomeAfastamento;
          codigoAfastamento = this.formValue.afastamento.codigoAfastamento;
          calculo = this.formValue.afastamento.calculo;
        }
        this.form.setValue({
          id: idAfastamento,
          orgao: this.formValue.orgao,
          nomeAfastamento,
          codigoAfastamento,
          calculo,
        });
      }

      this._orgaoService
        .buscarAfastamento(this.orgao.id)
        .subscribe((result) => {
          this.listaDeAfastamentos = result;
        });
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
