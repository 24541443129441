<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    {{ indicador?.indicadorNome | uppercase }}
  </span>
</mat-card>

<br />

<mat-card class="mat-elevation-z8">
  <form [formGroup]="form" class="flex-form-container">
    <mat-form-field appearance="outline" class="flex-item" *ngFor="let resultado of resultados.controls; let i = index">
      <mat-label aria-hidden="true">
        {{ resultado.value.dataResultado | date: 'MMM/yyyy' }}
      </mat-label> 

      <input
        attr.aria-label="Quantifique indicador para o mês  {{ resultado.value.data | date: 'MMMM/yyyy' }}"
        matInput
        type="text"
        [mask]="indicador['tipo'] === TipoIndicadorEnum.HORAS ? 'separator.2' : 'separator.0'"
        thousandSeparator="."
        min="0"
        [readonly]="readonly"
        [formControl]="resultado.get('quantidade')"
        (focus)="$event.target.select()"
        (blur)="$event.target.value = $event.target.value || '0'"
      />
    </mat-form-field>
  </form>
</mat-card>

<br />
