import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@dft/layout/layout.module';
import { LoginModule } from '@dft/layout/login/login.module';
import { SenhaFormComponent } from '@dft/layout/senha/senha.component';
import { DimensionamentoCalculoFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/dimensionamento-calculo-form/dimensionamento-calculo-form.component';
import { DimensionamentoCalculoTodosFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/dimensionamento-calculo-todos-form/dimensionamento-calculo-todos-form.component';
import { DimensionamentoDevolucaoFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/dimensionamento-devolucao-form/dimensionamento-devolucao-form.component';
import { DimensionamentoValidacaoFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/dimensionamento-validacao-form/dimensionamento-validacao-form.component';
import { QuantificacaoIndicadoresPessoalFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/quantificacao-indicadores-pessoal/quantificacao-indicadores-pessoal-form/quantificacao-indicadores-pessoal-form.component';
// tslint:disable-next-line:max-line-length
import { QuantificacaoEntregaFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/selecao-entregas/quantificacao-entrega-form/quantificacao-entrega-form.component';
import { SelecaoEntregasFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/selecao-entregas/selecao-entregas-form/selecao-entregas-form.component';
import { SelecaoIndicadoresProdutividadeFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/selecao-indicadores-produtividade/selecao-indicadores-produtividade-form/selecao-indicadores-produtividade-form.component';
import { InfoEtapasComponent } from '@dft/modules/dimensionamento/dimensionamentos/selecao-pessoas/quantificacao-esforcos/quantificacao-esforcos-form/info-etapas/info-etapas.component';
// tslint:disable-next-line:max-line-length
import { SelecaoPessoasFormComponent } from '@dft/modules/dimensionamento/dimensionamentos/selecao-pessoas/selecao-pessoas-form/selecao-pessoas-form.component';
import { HomeModule } from '@dft/modules/home/home.module';
import { IndicadorProdutividadeFormComponent } from '@dft/modules/tipificacao/indicador-produtividade/indicador-produtividade-form/indicador-produtividade-form.component';
import { SharedModule } from '@dft/shared/shared.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ParametroFormComponent } from './modules/configuracao/tabela-parametro/parametro-form/parametro-form.component';
import { ConsolidacaoEditDescricaoComponent } from './modules/dimensionamento/consolidacao/consolidacao-edit-descricao/consolidacao-edit-descricao.component';
import { ConsolidacaoFormComponent } from './modules/dimensionamento/consolidacao/consolidacao-form/consolidacao-form.component';
import { DimensionamentoFormComponent } from './modules/dimensionamento/dimensionamentos/dimensionamento-form/dimensionamento-form.component';
import { AlterarDescricaoEntregaFormComponent } from './modules/dimensionamento/dimensionamentos/selecao-entregas/alterar-descricao-entrega-form/alterar-descricao-entrega-form.component';
import { DadoFuncionalFormComponent } from './modules/pessoa-perfil/dado-funcional/dado-funcional-form/dado-funcional-form.component';
import { PerfilFormComponent } from './modules/pessoa-perfil/perfil/perfil-form/perfil-form.component';
import { PessoaAfastamentoFormComponent } from './modules/pessoa-perfil/pessoa-afastamento/pessoa-afastamento-form/pessoa-afastamento-form.component';
import { PessoaFormComponent } from './modules/pessoa-perfil/pessoa-form/pessoa-form.component';
import { RemuneracaoFormComponent } from './modules/pessoa-perfil/remuneracao/remuneracao-form/remuneracao-form.component';
import { CadeiaValorFormComponent } from './modules/tipificacao/cadeia-valor/cadeia-valor-form/cadeia-valor-form.component';
import { CategoriaServicoFormComponent } from './modules/tipificacao/categoria-servico/categoria-servico-form/categoria-servico-form.component';
import { EntregaAreasConhecimentoComponent } from './modules/tipificacao/entrega/entrega-form/entrega-areas-conhecimento/entrega-areas-conhecimento.component';
// tslint:disable-next-line: max-line-length
import { EntregaDadosGeraisComponent } from './modules/tipificacao/entrega/entrega-form/entrega-dados-gerais/entrega-dados-gerais.component';
import { EntregaFormComponent } from './modules/tipificacao/entrega/entrega-form/entrega-form.component';
import { EntregaFormacaoComponent } from './modules/tipificacao/entrega/entrega-form/entrega-formacao/entrega-formacao.component';
import { EntregaViewComponent } from './modules/tipificacao/entrega/entrega-view/entrega-view.component';
import { IndicadorFormComponent } from './modules/tipificacao/indicador-pessoal/indicador-form/indicador-form.component';
import { MapaUnidadeFormComponent } from './modules/tipificacao/mapa-unidade/mapa-unidade-form/mapa-unidade-form.component';
import { OrgaoAfastamentoFormComponent } from './modules/tipificacao/orgao/orgao-afastamento/orgao-afastamento-form/orgao-afastamento-form.component';
import { OrgaoCargoFormComponent } from './modules/tipificacao/orgao/orgao-cargo/orgao-cargo-form/orgao-cargo-form.component';
import { OrgaoEscolaridadeFormComponent } from './modules/tipificacao/orgao/orgao-escolaridade/orgao-escolaridade-form/orgao-escolaridade-form.component';
import { OrgaoFormComponent } from './modules/tipificacao/orgao/orgao-form/orgao-form.component';
import { OrgaoGratificacaoFormComponent } from './modules/tipificacao/orgao/orgao-gratificacao/orgao-gratificacao-form/orgao-gratificacao-form.component';
import { OrgaoSitFuncionalFormComponent } from './modules/tipificacao/orgao/orgao-sit-funcional/orgao-sit-funcional-form/orgao-sit-funcional-form.component';
import { OrgaoVinculoFormComponent } from './modules/tipificacao/orgao/orgao-vinculo/orgao-vinculo-form/orgao-vinculo-form.component';
import { UnidadeFormComponent } from './modules/tipificacao/unidade/unidade-form/unidade-form.component';
import { AuthService } from './security/auth/auth.service';
import { initializer } from './security/auth/keycloak-initializer';
import { HttpErrorInterceptor } from './security/interceptor/http-error.interceptor';
import { HttpRequestInterceptor } from './security/interceptor/http-request.interceptor';
import { MatPaginatorBr } from './shared/services/mat-paginator-br.service';
import { MensagemService } from './shared/services/mensagem.service';
import { MapaUnidadeProdutosFormComponent } from './modules/tipificacao/mapa-unidade/mapa-unidade-produtos/mapa-unidade-produtos-form/mapa-unidade-produtos-form.component';
import { MapaUnidadeCriarProdutosFormComponent } from './modules/tipificacao/mapa-unidade/mapa-unidade-produtos/mapa-unidade-criar-produtos-form/mapa-unidade-criar-produtos-form.component';
import { MapaUnidadeIndicadoresFormComponent } from './modules/tipificacao/mapa-unidade/mapa-unidade-produtos/mapa-unidade-indicadores-form/mapa-unidade-indicadores-form.component';
import { MapaUnidadeCriarIndicadoresFormComponent } from './modules/tipificacao/mapa-unidade/mapa-unidade-produtos/mapa-unidade-criar-indicadores-form/mapa-unidade-criar-indicadores-form.component';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    InfoEtapasComponent,
    OrgaoFormComponent,
    PessoaFormComponent,
    PerfilFormComponent,
    UnidadeFormComponent,
    DimensionamentoFormComponent,
    DimensionamentoDevolucaoFormComponent,
    DimensionamentoValidacaoFormComponent,
    DimensionamentoCalculoFormComponent,
    DimensionamentoCalculoTodosFormComponent,
    CategoriaServicoFormComponent,
    ParametroFormComponent,
    IndicadorProdutividadeFormComponent,
    IndicadorFormComponent,
    SelecaoIndicadoresProdutividadeFormComponent,
    SelecaoPessoasFormComponent,
    SelecaoEntregasFormComponent,
    QuantificacaoEntregaFormComponent,
    QuantificacaoIndicadoresPessoalFormComponent,
    AlterarDescricaoEntregaFormComponent,
    SenhaFormComponent,
    PessoaAfastamentoFormComponent,
    OrgaoAfastamentoFormComponent,
    OrgaoSitFuncionalFormComponent,
    OrgaoVinculoFormComponent,
    OrgaoEscolaridadeFormComponent,
    OrgaoCargoFormComponent,
    OrgaoGratificacaoFormComponent,
    RemuneracaoFormComponent,
    ConsolidacaoFormComponent,
    DadoFuncionalFormComponent,
    ConsolidacaoEditDescricaoComponent,
    CadeiaValorFormComponent,
    EntregaViewComponent,
    EntregaFormComponent,
    EntregaFormacaoComponent,
    EntregaDadosGeraisComponent,
    EntregaAreasConhecimentoComponent,
    MapaUnidadeFormComponent,
    MapaUnidadeProdutosFormComponent,
    MapaUnidadeCriarProdutosFormComponent,
    MapaUnidadeIndicadoresFormComponent,
    MapaUnidadeCriarIndicadoresFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressBarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatListModule,
    MatDatepickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    MatBadgeModule,
    MatTooltipModule,
    LoginModule,
    LayoutModule,
    HomeModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    KeycloakAngularModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatSortModule,
    MatTabsModule,
  ],
  providers: [
    MensagemService,
    AuthService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorBr,
    },
  ],
  entryComponents: [
    EntregaViewComponent,
    EntregaFormComponent,
    EntregaFormacaoComponent,
    EntregaDadosGeraisComponent,
    EntregaAreasConhecimentoComponent,
    InfoEtapasComponent,
    OrgaoFormComponent,
    PessoaFormComponent,
    PerfilFormComponent,
    UnidadeFormComponent,
    IndicadorProdutividadeFormComponent,
    IndicadorFormComponent,
    DimensionamentoFormComponent,
    DimensionamentoDevolucaoFormComponent,
    DimensionamentoValidacaoFormComponent,
    DimensionamentoCalculoFormComponent,
    DimensionamentoCalculoTodosFormComponent,
    CategoriaServicoFormComponent,
    ParametroFormComponent,
    SelecaoIndicadoresProdutividadeFormComponent,
    SelecaoPessoasFormComponent,
    SelecaoEntregasFormComponent,
    QuantificacaoEntregaFormComponent,
    QuantificacaoIndicadoresPessoalFormComponent,
    AlterarDescricaoEntregaFormComponent,
    SenhaFormComponent,
    PessoaAfastamentoFormComponent,
    OrgaoAfastamentoFormComponent,
    OrgaoSitFuncionalFormComponent,
    OrgaoVinculoFormComponent,
    OrgaoEscolaridadeFormComponent,
    OrgaoCargoFormComponent,
    OrgaoGratificacaoFormComponent,
    RemuneracaoFormComponent,
    ConsolidacaoFormComponent,
    DadoFuncionalFormComponent,
    ConsolidacaoEditDescricaoComponent,
    CadeiaValorFormComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
