<br />

<mat-card class="mat-elevation-z8">
  <span class="mat-body-strong" style="color: darkslateblue">
    {{ (entrega.apelidoEntrega || entrega.entregaNome) | uppercase }}
  </span>
</mat-card>

<br />

<mat-card class="mat-elevation-z8">
  <form [formGroup]="form" class="flex-form-container">
    <mat-tab-group >
      <mat-tab aria-label="Resultado">

        <ng-template mat-tab-label>
          Resultado
        </ng-template>
        <br >
        <legend>Resultado</legend>
        <mat-form-field
        appearance="outline"
        class="flex-item"
        *ngFor="let resultado of resultados.controls; let i = index"
        >
        <mat-label aria-hidden="true">
          {{ resultado.value.dataResultado | date: 'MMM/yyyy' }}
        </mat-label>
        <input
          attr.aria-label="Quantifique entrega para o mês  {{ resultado.value.dataResultado | date: 'MMMM/yyyy' }}"
          matInput
          type="number"
          min="0"
          max="1999999999"
          maxlength="10"
          [formControl]="resultado.get('quantidade')"
          (focus)="$event.target.select()"
          (blur)="$event.target.value = $event.target.value || '0'"
        />
        </mat-form-field>
      </mat-tab>
      <div *ngIf="metaAtivo">
      <mat-tab aria-label="Meta">
        <ng-template mat-tab-label>
          Meta
        </ng-template>
        <br >
        <legend>
          Demanda Reprimida <i class="fa fa-question-circle" 
            matTooltipPosition="right" 
            matTooltip="Trata-se da demanda que não foi resolvida e segue pendente na unidade 
              até o início do período dimensionado. É um passivo ou estoque que precisará ser 
              resolvido pela unidade."></i>
        </legend>
        <mat-form-field appearance="outline">
          <mat-label aria-hidden="true">
            Demanda Reprimida
          </mat-label>
          <input aria-label="Demanda Reprimida"
            matInput
            type="number"
            max="1999999999"
            maxlength="10"
            placeholder="Ex.: 250"
            name="demandaReprimida"
            formControlName="demandaReprimida"
          />
        </mat-form-field>
        <legend>Meta <i class="fa fa-question-circle" 
            matTooltipPosition="right" 
            matTooltip="Refere-se ao quantitativo de entregas que a unidade 
            deveria atender mensalmente durante o período dimensionado. Em alguns casos,
            a meta mensal pode ser representada pelo número de demandas recebidas pela 
            unidade naquele mês."></i>
        </legend>
        <mat-form-field appearance="outline" class="flex-item" *ngFor="let resultado of resultados.controls; let i = index">
          <mat-label aria-hidden="true">
            {{ resultado.value.dataResultado | date: 'MMM/yyyy' }}
          </mat-label>
          <input
            attr.aria-label="Quantifique meta para o mês  {{ resultado.value.dataResultado | date: 'MMMM/yyyy' }}"
            matInput
            type="number"
            min="0"
            max="1999999999"
            maxlength="10"
            name="resultado.value.meta"
            [formControl]="resultado.get('meta')"
            (focus)="$event.target.select()"
            (blur)="$event.target.value = $event.target.value || '0'"
          />
        </mat-form-field>
      </mat-tab>
      </div>
    </mat-tab-group>
  </form>
</mat-card>

<br />
