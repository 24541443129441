<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Selecione um Produto</mat-label>
    <mat-select formControlName="idEntrega" [disabled]="readonly">
      <mat-option *ngFor="let produto of produtosDisponiveis" [value]="produto.id">
        {{ produto.nome }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('idEntrega').hasError('required')">
      É necessário selecionar um produto.
    </mat-error>
  </mat-form-field>
</form>
