import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { DimensionamentoValidacao } from '@dft/shared/models/dimensionamento-validacao';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'dimensionamento-validacao-form.component.html',
})
export class DimensionamentoValidacaoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  dimensionamento: Dimensionamento;
  validacoes: DimensionamentoValidacao[];
  valido: boolean;

  constructor(
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
    });
  }

  ngOnInit() {
    this.dimensionamento = this.formValue['dimensionamento'] as Dimensionamento;
    this.validacoes = this.formValue['validacoes'] as DimensionamentoValidacao[];
    this.valido = this.formValue['valido'] as boolean;
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
