import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separadorLista'
})
export class SeparadorListaPipe implements PipeTransform {
  transform(nome: string, indice: number, tamanhoArray: number): string {
    if (indice !== tamanhoArray - 1) { return nome + ' | '; }
    return nome;
  }

}
