import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Perfil } from '../models/perfil';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  apiPerfis = `${environment.urlApi}/perfis`;

  constructor(private http: HttpClient) {}

  public listarPerfis() {
      return this.http.get<Perfil[]>(`${this.apiPerfis}`, httpOptions);
  }
}
