<form [formGroup]="form">
  <div
    [ngClass]="{
      'filtro-comum' : !delineado,
      'filtro-delineado' : delineado,
      'selecao-unica' : selecaoUnica,
      'selecao-invalida' : delineado && control?.invalid && elementoSemFoco
      }"
  >
    <ng-multiselect-dropdown [attr.aria-label]="placeholder"
      tabindex="0"
      [settings]="dropdownSettings"
      [placeholder]="placeholder"
      [data]="listaDeOpcoes"
      [disabled]="desabilitado"
      formControlName="itens"
      (onSelect)="onSelect($event)"
      (onDeSelect)="onSelect(null)"
      (onSelectAll)="marcarEDesmarcarTodos($event)"
      (onDeSelect)="onDeSelect($event)"
      (onDeSelectAll)="marcarEDesmarcarTodos($event)"
      (focusout)="defineElementoSemFoco()">
    </ng-multiselect-dropdown>
  </div>
</form>