<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="pessoa" attr.aria-hidden="true">Pessoa</mat-label>
    <input
      matInput
      name="pessoa"
      aria-label="Nome da pessoa"
      [value]="form.controls['pessoa']?.value?.nome"
      disabled
    />
  </mat-form-field>
  
  <br/>
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Período da Remuneração</mat-label>
    <input aria-label="Período da Remuneração"
      matInput
      placeholder="Ex.: 01/2018"
      name="dataRemuneracao"
      [matDatepicker]="dtRemuneracao"
      formControlName="dataRemuneracao"
      (change)="normalizaDataDigitada()"
      required
    />
    <mat-datepicker-toggle matSuffix [for]="dtRemuneracao"></mat-datepicker-toggle>
    <mat-datepicker #dtRemuneracao startView="multi-year"
      (yearSelected)="escolhaAnoInicioHandler($event)"
      (monthSelected)="escolhaMesInicioHandler($event, dtRemuneracao)"
    ></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Valor Remuneração (R$)</mat-label>
    <input
      matInput
      name="valorRemuneracao"
      type="text"
      thousandSeparator="."
      [mask]="'separator.0'"
      min="0"
      placeholder="Ex.: 1500.00"
      formControlName="valorRemuneracao"
    />
  </mat-form-field>

</form>
