import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Pessoa } from '@dft/shared/models/pessoa';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  templateUrl: './remuneracao-form.component.html',
  styleUrls: ['./remuneracao-form.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RemuneracaoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;
  dataRemuneracaoSelecionada: string = null;

  pessoa: Pessoa;

  constructor(private _formBuilder: FormBuilder) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      pessoa: this._formBuilder.control(null),
      dataRemuneracao: this._formBuilder.control(null, Validators.required),
      valorRemuneracao: this._formBuilder.control(null, Validators.required),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.pessoa = this.formValue.pessoa;

      if (this.formValue.pessoaRemuneracao) {
        this.form.setValue({
          id: this.formValue.pessoaRemuneracao.id,
          pessoa: this.formValue.pessoa,
          dataRemuneracao: this.formValue.pessoaRemuneracao.dataRemuneracao,
          valorRemuneracao: this.formValue.pessoaRemuneracao.valorRemuneracao,
        });
      } else {
        this.form.setValue({
          id: null,
          pessoa: this.formValue.pessoa,
          dataRemuneracao: null,
          valorRemuneracao: null,
        });
      }
    }
  }

  escolhaAnoInicioHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.form.controls.dataRemuneracao.value
      ? moment(this.form.controls.dataRemuneracao.value, 'YYYY-MM')
      : moment();
    ctrlValue.year(normalizedYear.year());
    this.form.controls.dataRemuneracao.setValue(ctrlValue);
  }

  escolhaMesInicioHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.form.controls.dataRemuneracao.value
      ? moment(this.form.controls.dataRemuneracao.value, 'YYYY-MM')
      : moment();
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1);
    this.form.controls.dataRemuneracao.setValue(ctrlValue);

    this.dataRemuneracaoSelecionada = this.form.controls.dataRemuneracao.value.format(
      'YYYY-MM-DD'
    );
    this.form.controls.dataRemuneracao.setValue(
      this.dataRemuneracaoSelecionada
    );
    datepicker.close();
  }

  normalizaDataDigitada() {
    this.form.controls.dataRemuneracao.setValue(
      this.form.controls.dataRemuneracao.value.format('YYYY-MM-DD')
    );
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
