import { Lista } from '../models/lista';

export enum CapacidadeEnum {
  IM = 'IM', // Imprevisível
  PD = 'PD', // Previsível por demanda
  PA = 'PA' // Previsível agendada
}

export const CAPACIDADE_MAP: Lista[] = [
  { id: CapacidadeEnum.IM, descricao: 'Imprevisível', detalhamento: 'A produção dessa entrega não pode ser prevista. Surge inesperadamente como demanda extraordinária. (Ex.: atendimentos ao ministro)' },
  { id: CapacidadeEnum.PD, descricao: 'Previsível por demanda', detalhamento: 'A produção dessa entrega não pode ser prevista com exatidão, apenas com base em séries históricas e estimativas. Surge ao longo do período através de demandas externas e internas da organização (Ex.: Processos de ressarcimento analisados)' },
  { id: CapacidadeEnum.PA, descricao: 'Previsível agendada', detalhamento: 'A produção dessa entrega pode ser prevista com exatidão. É pré-estabelecida através de determinações da organização ou de atores externos (ex.: relatórios semestrais).' },
];
