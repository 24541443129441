import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { CategoriaServico } from '@dft/shared/models/categoria-servico';
import { CategoriaServicoService } from '@dft/shared/services/categoria-servico.service';
import { MensagemService } from 'src/app/shared/services/mensagem.service';
import { Orgao } from '@dft/shared/models/orgao';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import { TipoPoderEnum, TIPO_PODER_MAP } from '@dft/shared/enums/tipo-poder.enum';
import { TipoOrgaoEnum, TIPO_ORGAO_MAP } from '@dft/shared/enums/tipo-orgao.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './orgao-form.component.html',
  styleUrls: ['./orgao-form.component.scss'],
})
export class OrgaoFormComponent implements OnInit, FormComponent {

  form: FormGroup;
  metodologiasTipicas: CategoriaServico[];
  metodologiasAtipicas: CategoriaServico[];
  formValue = null;
  readonly = false;
  esconderListaOrgaos = false;
  listaDeOrgaos: Orgao[] = [];
  orgaoClone: Orgao;

  poderes = TIPO_PODER_MAP;
  orgaos = TIPO_ORGAO_MAP;

  tipoPoder: TipoPoderEnum;
  tipoOrgao: TipoOrgaoEnum;

  constructor(
    private formBuilder: FormBuilder,
    private mensagemService: MensagemService,
    private categoriaService: CategoriaServicoService,
    private orgaoService: OrgaoService,
  ) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(''),
      descricao: this.formBuilder.control('', Validators.required),
      sigla: this.formBuilder.control('', Validators.required),
      codigoReferencia: this.formBuilder.control(null),
      metodologiasTipicas: this.formBuilder.control(null),
      metodologiasAtipicas: this.formBuilder.control(null),
      orgaoClone: this.formBuilder.control(null),
      visibilidadeGestorOrgao: this.formBuilder.control(true),
      tipoPoder: this.formBuilder.control(null),
      tipoOrgao: this.formBuilder.control(null),
    });
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  public setEsconderListaOrgaos(esconderListaOrgaos: boolean): void {
    this.esconderListaOrgaos = esconderListaOrgaos;
  }

  compareFn(f1: CategoriaServico, f2: CategoriaServico) {
    return f1 && f2 && f1.id === f2.id;
  }

  getListaDeOrgaos(orgaos): any {
    this.listaDeOrgaos = orgaos;
    if (orgaos.length === 1) {
      this.form.controls['orgao'].setValue(this.listaDeOrgaos[0]);
      this.orgaoClone = this.listaDeOrgaos[0];
    }
  }

  ngOnInit(): void {
    this.orgaoService.listarOrgaos().subscribe(
      (orgaos) => {
        this.getListaDeOrgaos(orgaos);
      }
    );
    this.categoriaService.listarCategorias().subscribe(
      categorias => {
        this.metodologiasTipicas = categorias.filter(metodologia => metodologia.tipo === TipoMetodologiaEnum.TIPICA);
        this.metodologiasAtipicas = categorias.filter(metodologia => metodologia.tipo === TipoMetodologiaEnum.ATIPICA);
        if (this.formValue) {
          this.form.setValue({
            id: this.formValue.id,
            descricao: this.formValue.descricao,
            sigla: this.formValue.sigla,
            metodologiasTipicas: this.formValue.categoriaServicos.filter(metodologia => metodologia.tipo === 'TIPICA'),
            metodologiasAtipicas: this.formValue.categoriaServicos.filter(metodologia => metodologia.tipo === 'ATIPICA'),
            codigoReferencia: this.formValue.codigoReferencia,
            orgaoClone: this.formValue.orgaoClone,
            visibilidadeGestorOrgao: !this.formValue.gestorOrgaoSemVisibilidade,
            tipoPoder: this.formValue.tipoPoder,
            tipoOrgao: this.formValue.tipoOrgao
          });
          this.esconderListaOrgaos = true;
        }
      },
      err => {
        this.mensagemService.erro(err, 2000);
      }
    );

    if (this.formValue) {
      const poderesAux = this.poderes.find(f => f.id === this.formValue.tipoPoder);
      if (poderesAux) {
        this.formValue.tipoPoder = poderesAux;
        this.form.controls.tipoPoder.setValue(poderesAux);
      }

      const tipoOrgaoAux = this.orgaos.find(f => f.id === this.formValue.tipoOrgao);
      if (tipoOrgaoAux) {
        this.formValue.tipoOrgao = tipoOrgaoAux;
        this.form.controls.tipoOrgao.setValue(tipoOrgaoAux);
      }
    }
  }

  selecionarOrgaoParaClonarAfastamentos(event: any) {
    this.orgaoClone = this.listaDeOrgaos.find(
      (o) => o.id === event.id
    );
  }

}
