import { Component } from '@angular/core';

@Component({
  selector: 'dft-botao-voltar',
  template: `
    <a mat-icon-button role="button" aria-label="Voltar para a tela anterior">
      <mat-icon class="material-icons-filled" attr.aria-hidden="true">arrow_back</mat-icon>
    </a>
  `,
  styles: ['a { float: left; margin: -10px 0 0 -5px; }']
})
export class BotaoVoltarComponent { }
