import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { TipoMetodologiaEnum } from '@dft/shared/enums/metodologia.enum';
import { periodoCalculoValidator } from './periodo-calculo.validator';
import * as moment from 'moment';

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'dimensionamento-calculo-form.component.html',
  styleUrls: ['dimensionamento-calculo-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class DimensionamentoCalculoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;
  dimensionamento: Dimensionamento;
  personalizacaoPeriodoMarcada = false;

  constructor(private _formBuilder: FormBuilder) {
    this.form = this._formBuilder.group({
      indice: this._formBuilder.control(0.0),
      meta: this._formBuilder.control(0.0),
      personalizacaoPeriodo: this._formBuilder.control(false),
      dataInicio: this._formBuilder.control(undefined),
      dataFim: this._formBuilder.control(undefined)
    });
  }

  ngOnInit() {
    this.dimensionamento = this.formValue['dimensionamento'] as Dimensionamento;
  }

  definePersonalizacaoPeriodo(event: MatSlideToggleChange): void {
    this.personalizacaoPeriodoMarcada = event.checked;

    const inputDataInicio = this.form.get('dataInicio');
    const inputDataFim = this.form.get('dataFim');
    if (this.personalizacaoPeriodoMarcada) {
      inputDataInicio.setValidators([Validators.required, periodoCalculoValidator(
        this.dimensionamento.dataInicio, this.dimensionamento.dataFim)]);
      inputDataFim.setValidators([Validators.required, periodoCalculoValidator(
        this.dimensionamento.dataInicio, this.dimensionamento.dataFim)]);
    } else {
      inputDataInicio.setValue('');
      inputDataInicio.clearValidators();
      inputDataInicio.updateValueAndValidity();
      inputDataFim.setValue('');
      inputDataFim.clearValidators();
      inputDataFim.updateValueAndValidity();
    }
  }

  escolhaMesInicioHandler(mes: moment.Moment, datepicker: MatDatepicker<moment.Moment>): void {
    this.form.controls.dataInicio.setValue(mes);
    datepicker.close();
  }

  escolhaMesFimHandler(mes: moment.Moment, datepicker: MatDatepicker<moment.Moment>): void {
    this.form.controls.dataFim.setValue(mes);
    datepicker.close();
  }

  public isDimensionamentoTipico(): boolean {
    return this.dimensionamento.tipo === TipoMetodologiaEnum.TIPICA;
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
