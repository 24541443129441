import { Lista } from '../models/lista';

export enum TipoPoderEnum {
  EXECUTIVO = 'EXECUTIVO',
  JUDICIARIO = 'JUDICIARIO',
  LEGISLATIVO = 'LEGISLATIVO',
  MINISTERIO_PUBLICO = 'MINISTERIO_PUBLICO',
}

export const TIPO_PODER_MAP: Lista[] = [
  { id: TipoPoderEnum.EXECUTIVO, descricao: 'Executivo', detalhamento: '' },
  { id: TipoPoderEnum.JUDICIARIO, descricao: 'Judiciário', detalhamento: '' },
  { id: TipoPoderEnum.LEGISLATIVO, descricao: 'Legislativo', detalhamento: '' },
  { id: TipoPoderEnum.MINISTERIO_PUBLICO, descricao: 'Ministério Público', detalhamento: '' },
];
