import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PessoaPerfil } from '@dft/shared/models/pessoa-perfil';
import { PessoaSessao } from '@dft/shared/models/usuario-sessao';

@Component({
  selector: 'dft-info-usuario',
  templateUrl: './info-usuario.component.html',
  styleUrls: ['./info-usuario.component.scss']
})
export class InfoUsuarioComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {pessoa: PessoaSessao, perfil: PessoaPerfil, authLocal: boolean}) {}
}
