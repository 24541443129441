<form [formGroup]="form">
  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Nome da Cadeia de Valor
    </mat-label>
    <input aria-label="Nome da Cadeia de Valor"
      matInput
      name="nome"
      placeholder="Ex.: Desenvolver pessoas"
      formControlName="nome"
      [readonly]="readonly"
      maxlength="255"
      required
    />
  </mat-form-field>
</form>
