import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalHoras',
})
export class TotalHoras implements PipeTransform {
  transform(quantidade: number): string {
    return (
      quantidade.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + ' h'
    );
  }
}
