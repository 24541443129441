import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { Parametro } from '../models/parametro';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ParametroService {
  apiParametros = `${environment.urlApi}/parametros`;

  constructor(private http: HttpClient) {}

  public listar(): Observable<Parametro[]> {
    return this.http.get<Parametro[]>(`${this.apiParametros}`);
  }

  public editar(parametro: Parametro): Observable<Parametro> {
    parametro.valor = parametro.valor.trim();
    return this.http.put<Parametro>(
      `${this.apiParametros}/${parametro.id}`,
      parametro,
      httpOptions
    );
  }

  public buscarParametro(nome: string): Observable<Parametro> {
    return this.http.get<Parametro>(`${this.apiParametros}/${nome}`);
  }
}
