import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.podeProsseguir(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.podeProsseguir(next);
  }

  private podeProsseguir(next: ActivatedRouteSnapshot): Observable<boolean> {
    // Redireciona para tela de login se não autenticado
    return this.authService
      .checkSessaoAtiva()
      .pipe(
        catchError(() => of(false)),
        tap(isAutenticado => {
          if (!isAutenticado) {
            this.router.navigate(['login']);
            return false;
          }

          // Verifica se necessário perfil para autenticar
          const perfil = this.authService.perfilSelecionado$.value;
          if (perfil && next.data.roles?.indexOf(perfil.descricaoPerfil) === -1) {
            // Perfil não autorizado a acessar a página. Redireciona para erro 403.
            this.router.navigate(['/error/403']);
            return false;
          }

          // Caso o usuário possui sessão.
          return true;
        })
      );
  }
}
