import { AbstractControl, ValidatorFn } from '@angular/forms';

export function periodoCalculoValidator(dataInicio: string, dataFim: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value && (control.value.isBefore(dataInicio) || control.value.isAfter(dataFim))) {
            return { dataForaPeriodo: true };
        }
        return null;
    };
}
