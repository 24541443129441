import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Formacao } from '../models/formacao';

const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

@Injectable({ providedIn: 'root' })
export class FormacaoService {

  private readonly api = `${environment.urlApi}/formacoes`;
  private readonly apiGrandeArea = `${this.api}/grandes-areas`;
  private readonly apiAreaBasica = `${this.api}/areas-basicas`;
  private readonly apiEspecialidade = `${this.api}/especialidades`;

  constructor(private http: HttpClient) {}

  listarGrandesAreas(): Observable<Formacao[]> {
    return this.http.get<Formacao[]>(`${this.apiGrandeArea}`, { headers });
  }

  listarAreasBasicas(codigoGrandeArea: string): Observable<Formacao[]> {
    const params = new HttpParams().set('grandeArea', codigoGrandeArea);
    return this.http.get<Formacao[]>(`${this.apiAreaBasica}`, { headers, params });
  }

  listarEspecialidades(codigoAreaBasica: string): Observable<Formacao[]> {
    const params = new HttpParams().set('areaBasica', codigoAreaBasica);
    return this.http.get<Formacao[]>(`${this.apiEspecialidade}`, { headers, params });
  }
}
