import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Vinculo } from '../models/vinculo';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class VinculoService {

  constructor(private http: HttpClient) {}

  public listarVinculos(idOrgao: number): Observable<Vinculo[]> {
    return this.http.get<Vinculo[]>(`${environment.urlApi}/orgaos/${idOrgao}/vinculos`, httpOptions);
  }

  public criarVinculo(vinculo: Vinculo) {
    return this.http.post<Vinculo>(
      `${environment.urlApi}/orgaos/${vinculo.orgao.id}/vinculos`,
      vinculo,
      httpOptions);
  }

  public editarVinculo(vinculo: Vinculo) {
    return this.http.put<Vinculo>(
      `${environment.urlApi}/orgaos/${vinculo.orgao.id}/vinculos/${vinculo.id}`,
      vinculo,
      httpOptions);
  }

  public excluirVinculo(vinculo: Vinculo) {
    return this.http.delete<Vinculo>(
      `${environment.urlApi}/orgaos/${vinculo.orgao.id}/vinculos/${vinculo.id}`
    );
  }
}
