import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Senha } from '@dft/shared/models/senha';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Orgao } from '../models/orgao';
import { Pessoa } from '../models/pessoa';
import { ResultadoPaginado } from '../models/resultado-paginado';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class PessoaService {

  private readonly apiPessoas = `${environment.urlApi}/pessoas`;

  constructor(private http: HttpClient) { }

  public listarPessoas(idOrgao?: number, numeroPagina: number = 1, tamanhoPagina: number = 10,
    filtro?: string, campo: string = 'nome', ordem: string = 'ASC') {

    let params: HttpParams = new HttpParams();
    if (idOrgao) {
      params = params.set('orgaoId', idOrgao.toString());
    }
    params = params.set('cpf', filtro);
    params = params.set('nome', filtro);
    params = params.set('pagina', numeroPagina.toString());
    params = params.set('tamanhoPagina', tamanhoPagina.toString());
    params = params.set('campo', campo);
    params = params.set('ordem', ordem);

    const options = { ...httpOptions, params };
    return this.http.get<ResultadoPaginado<Pessoa>>(`${this.apiPessoas}/paginada`, options);
  }

  public buscarPessoa(idPessoa: number) {
    return this.http.get<Pessoa>(`${this.apiPessoas}/${idPessoa}`, httpOptions);
  }

  public buscarPessoaPor(filtro: Partial<Pessoa>) {
    let params: HttpParams = new HttpParams();
    if (filtro.cpf) {
      params = params.set('cpf', filtro.cpf);
    }
    if (filtro.nome) {
      params = params.set('nome', filtro.nome);
    }
    if (filtro.nomeCpf) {
      params = params.set('nomeCpf', filtro.nomeCpf);
    }
    if (filtro.orgao) {
      params = params.set('orgaoId', filtro.orgao.id.toString());
    }

    const options = { ...httpOptions, params };
    return this.http.get<Pessoa[]>(this.apiPessoas, options);
  }

  public editarPessoa(pessoa: Pessoa) {
    return this.http
      .put<Pessoa>(`${this.apiPessoas}/${pessoa.id}`, pessoa, httpOptions);
  }

  public trocarSenha(senha: Senha) {
    return this.http
      .put(`${this.apiPessoas}/${senha.idUsuario}/senha`, senha, httpOptions);
  }

  public inserirPessoa(pessoa: Pessoa) {
    return this.http
      .post<Pessoa>(`${this.apiPessoas}`, pessoa, httpOptions);
  }

  public deletarPessoa(pessoa) {
    return this.http.delete<Pessoa>(`${this.apiPessoas}/${pessoa.id}`, pessoa);
  }

  public buscarDadosDaPessoaNoOrgao(orgao: Orgao, cpf: string) {
    let params = new HttpParams();
    params = params.set('idOrgao', orgao.id.toString());
    params = params.set('cpf', cpf);
    const options = { ...httpOptions, params };

    return this.http.get<Pessoa>(`${this.apiPessoas}/detalhes`, options);
  }

  /**
   * Altera o perfil do usuário logado no backend.
   */
  alterarPerfil(idPerfil: number) {
    return this.http.put(`${this.apiPessoas}/perfil`, { idPerfil }, httpOptions);
  }

  importarRendimentos(idOrgao: number, ano: number): Observable<void> {
    return this.http.get<void>(`${this.apiPessoas}/remuneracoes/importar`, {
      params: {
        orgao: `${idOrgao}`,
        ano: `${ano}`
      }
    });
  }
}
