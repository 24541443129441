import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Pessoa } from '@dft/shared/models/pessoa';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import { Afastamento } from '@dft/shared/models/afastamento';

@Component({
  templateUrl: './pessoa-afastamento-form.component.html',
  styleUrls: ['./pessoa-afastamento-form.component.scss'],
})
export class PessoaAfastamentoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;


  pessoa: Pessoa;
  listaDeAfastamentos: Afastamento[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _orgaoService: OrgaoService
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      pessoa: this._formBuilder.control(null),
      afastamento: this._formBuilder.control(null, Validators.required),
      dataInicio: this._formBuilder.control(null, Validators.required),
      dataFim: this._formBuilder.control(null, Validators.required),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.pessoa = this.formValue.pessoa;
      if (this.formValue) {
        let idPessoaAfastamento = 0;
        if (this.formValue.afastamento) {
          idPessoaAfastamento = this.formValue.afastamento.id;
        }
        this.form.setValue({
          id: idPessoaAfastamento,
          pessoa: this.formValue.pessoa,
          afastamento: null,
          dataInicio: null,
          dataFim: null,
        });
      }

      this._orgaoService
        .buscarAfastamento(this.pessoa.orgao.id)
        .subscribe((result) => {
          this.listaDeAfastamentos = result;
        });
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
