import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STATUS_MAPA_UNIDADE, StatusMapaUnidadeEnum } from '@dft/shared/enums/status-mapa-unidade.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mapa-unidade-form.component.html',
  styleUrls: ['./mapa-unidade-form.component.scss'],
})
export class MapaUnidadeFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  statusMapaUnidade = STATUS_MAPA_UNIDADE;
  statusMapaUnidadeEnum = StatusMapaUnidadeEnum;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      id: [''],
      nome: ['', Validators.required],
      status: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.formValue) {
      this.form.patchValue(this.formValue);
      this.form.controls.status.setValue(this.statusMapaUnidade.find(f => f.id === this.formValue.status));
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
