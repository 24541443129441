import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantidade'
})
export class QuantidadePipe implements PipeTransform {
  transform(quantidade: number): string {
    return (quantidade).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

}
