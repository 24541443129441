import { StatusDimensionamentoEnum } from 'src/app/shared/enums/status-dimensionamento.enum';
import { Unidade } from './unidade';
import { TipoMetodologiaEnum } from '../enums/metodologia.enum';

export class DimensionamentoSimples {
  public id: number;
  public unidade: Unidade;
  public dataInicio: string;
  public dataFim: string;
  public dataEnvio: string;
  public descricao: string;
  public nome: string;
  public idPessoaCriacao: number;
  public status: StatusDimensionamentoEnum;
  public tipo: TipoMetodologiaEnum;
  public metaAtivo: boolean;
  public registroProducao: boolean;

  constructor(
    id?: number,
    unidade?: Unidade,
    descricao?: string,
    dataInicio?: string,
    dataFim?: string,
    tipo?: TipoMetodologiaEnum,
    metaAtivo?: boolean,
    registroProducao?: boolean
  ) {
    this.id = id;
    this.unidade = unidade;
    this.descricao = descricao;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.tipo = tipo;
    this.metaAtivo = metaAtivo;
    this.registroProducao = registroProducao;
  }
}
