export function getStringSemAcento(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
}

export function getStringSemPontos(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .replace('.', '')
    .trim();
}

export function getStringCPFSemMascara(str: string) {
  return str
    .replace(/\.*-*/g, '')
    .trim();
}

export function getPrimeiraMaiuscula(str: string) {
  return str[0].toUpperCase() + str.substr(1);
}

/**
 * Trata texto passado de forma a deixar somente primeira letra de cada palavra maiúscula.
 *
 * Exemplos:
 *    "FULANO DE TAL" ==> Fulano De Tal;
 *    "fulano de tal" ==> Fulano De Tal;
 *
 * @param str texto a ser tratado
 *
 */
export function getTitleCase(str: string) {
  const texto = str.toLowerCase().split(' ');
  for (let i = 0; i < texto.length; i++) {
    texto[i] = texto[i][0].toUpperCase() + texto[i].slice(1);
  }
  return texto.join(' ');
}
