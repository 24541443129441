<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="orgao" attr.aria-hidden="true">Organização</mat-label>
    <input
      matInput
      name="orgao"
      aria-label="Orgão"
      [value]="form.controls['orgao']?.value?.descricao"
      disabled
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="nomeAfastamento" attr.aria-hidden="true">Afastamento</mat-label>
    <input
      matInput
      name="nomeAfastamento"
      formControlName="nomeAfastamento"
      aria-label="Afastamento"
      [value]="form.controls['nomeAfastamento']?.value"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="codigoAfastamento" attr.aria-hidden="true">Código do Afastamento</mat-label>
    <input
      matInput
      name="codigoAfastamento"
      formControlName="codigoAfastamento"
      aria-label="Código do Afastamento"
      [value]="form.controls['codigoAfastamento']?.value"
    />
  </mat-form-field>

</form>
