<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="pessoa" attr.aria-hidden="true">Pessoa</mat-label>
    <input
      matInput
      name="pessoa"
      aria-label="Nome da pessoa"
      [value]="form.controls['pessoa']?.value?.nome"
      disabled
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="orgao" attr.aria-hidden="true">Organização</mat-label>
    <input
      matInput
      name="orgao"
      aria-label="Organização da pessoa"
      [value]="form.controls['pessoa']?.value?.orgao?.descricao"
      disabled
    />
  </mat-form-field>

  <div class="flex-form-container">
    <div [ngClass]="{'flex-selecao-perfil' : exibeSelecaoPerfilValidador, perfil: !exibeSelecaoPerfilValidador }">
      <mat-label for="perfil" attr.aria-hidden="true">Perfis</mat-label>
      <dft-multiselect
        formControlName="perfil"
        placeholder="Selecione o perfil"
        [delineado]="true"
        [selecaoUnica]="true"
        ordenacao="descricao"
        [listaDeOpcoes]="listaDePerfis">
      </dft-multiselect>
    </div>

    <div class="flex-checkbox-validador" *ngIf="exibeSelecaoPerfilValidador">
      <mat-slide-toggle color="primary" formControlName="isPerfilValidador" >
        Validador ?
      </mat-slide-toggle>
    </div>
  </div>

  <br />

  <div *ngIf="exibeListaDeUnidades">
    <mat-label for="Unidade Produtiva" attr.aria-hidden="true">Unidade Produtiva</mat-label>
    <dft-multiselect
      formControlName="unidade"
      placeholder="Selecione a unidade"
      permitirFiltroDePesquisa="true"
      [delineado]="true"
      [selecaoUnica]="true"
      [listaDeOpcoes]="listaDeUnidades"
      ordenacao="sigla"
      campoDeTexto="bindName">
    </dft-multiselect>
  </div>

</form>
