<form [formGroup]="form" class="container">

  <input type="hidden" formControlName="id" />

  <mat-form-field appearance="outline">
    <mat-label for="orgao" attr.aria-hidden="true">Organização</mat-label>
    <input
      matInput
      name="orgao"
      aria-label="Orgão"
      [value]="form.controls['orgao']?.value?.descricao"
      disabled
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="nomeVinculo" attr.aria-hidden="true">Vínculo</mat-label>
    <input
      matInput
      name="nomeVinculo"
      formControlName="nome"
      aria-label="Vínculo"
      [value]="form.controls['nome']?.value"
      required
    />
  </mat-form-field>

</form>
