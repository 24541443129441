import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STATUS_MAPA_UNIDADE, StatusMapaUnidadeEnum } from '@dft/shared/enums/status-mapa-unidade.enum';
import { Orgao } from '@dft/shared/models/orgao';
import { Unidade } from '@dft/shared/models/unidade';
import { OrgaoService } from '@dft/shared/services/orgao.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mapa-unidade-form.component.html',
  styleUrls: ['./mapa-unidade-form.component.scss'],
})
export class MapaUnidadeFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  orgaos: Orgao[];
  unidades: Unidade[];

  statusMapaUnidade = STATUS_MAPA_UNIDADE;
  statusMapaUnidadeEnum = StatusMapaUnidadeEnum;

  constructor(
    private formBuilder: FormBuilder,
    private readonly orgaoService: OrgaoService,
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      orgao: ['', Validators.required],
      unidade: ['', Validators.required],
      nome: ['', Validators.required],
      status: ['', Validators.required]
    });

    this.form.get('orgao').valueChanges.subscribe((orgao) => {
      this.atualizarListaUnidadesOrgao(orgao);
    });
  }

  ngOnInit(): void {
    this.orgaoService.listarOrgaos().subscribe(
      (orgaos) => {
        this.orgaos = orgaos;
        this.form.controls.orgao.setValue(orgaos.find(o => o.id === this.formValue.idOrgao));
      },
      (error) => {
        console.error('Erro ao carregar os órgãos:', error);
      }
    );

    if (this.formValue) {
      this.form.patchValue(this.formValue);
      this.form.controls.status.setValue(this.statusMapaUnidade.find(f => f.id === this.formValue.status));
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  private atualizarListaUnidadesOrgao(orgao?: Orgao) {
    if (orgao) {
      this.orgaoService.buscarUnidadesFilhas(orgao.id).subscribe((unidades) => {
        this.unidades = unidades;
        this.form.controls.unidade.setValue(unidades.find(u => u.id === this.formValue.idUnidade));
      },
        (error) => {
          console.error('Erro ao carregar as unidades:', error);
        }
      );
    }
  }
}
