export enum StatusEntregaEnum {
  ATIVA = 'ATIVA',
  INATIVA = 'INATIVA',
  NAO_VALIDADA = 'NAO_VALIDADA'
}

export const STATUS_ENTREGA_MAP: Array<{ id: string, descricao: string }> = [
  { id: StatusEntregaEnum.ATIVA, descricao: 'Ativa' },
  { id: StatusEntregaEnum.INATIVA, descricao: 'Inativa' },
  { id: StatusEntregaEnum.NAO_VALIDADA, descricao: 'Não Validada' },
];
