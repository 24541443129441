<form [formGroup]="form" class="form-etapas-width">
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Entrega</mat-label>
    <input
      aria-label="Entrega"
      matInput
      formControlName="entrega"
      [readonly]="readonly"
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Atividade</mat-label>
    <input
      matInput
      aria-label="Atividade"
      formControlName="atividade"
      [readonly]="readonly"
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Fluxo de Trabalho</mat-label>
    <textarea
      matInput
      aria-label="Fluxo de trabalho"
      rows="5"
      formControlName="fluxoTrabalho"
      [readonly]="readonly"
    ></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Fonte</mat-label>
    <input
      matInput
      aria-label="Fonte"
      formControlName="fonte"
      [readonly]="readonly"
    />
  </mat-form-field>
</form>
