import {
  Directive,
  Input,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnInit,
  Type,
} from '@angular/core';

import { FormComponent } from './dialog.service';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[dftDialog]',
  exportAs: 'dftDialog',
})
export class DialogDirective implements OnInit {
  @Input() content: Type<FormComponent>;
  @Input() value: object;
  @Input() readonly = false;

  get form(): FormGroup {
    return this.componentRef.instance.form;
  }

  private componentRef: ComponentRef<FormComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const cf = this.resolver.resolveComponentFactory<FormComponent>(
      this.content
    );
    this.componentRef = this.container.createComponent(cf);
    this.componentRef.instance.setFormReadOnly(this.readonly);
    if (this.value) {
      this.componentRef.instance.setFormValue(this.value);
    }
  }
}
