import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormacaoEnum, FORMACAO_MAP } from '@dft/shared/enums/formacao.enum';
import { Entrega } from '@dft/shared/models/entrega';
import { Formacao } from '@dft/shared/models/formacao';
import { EntregaService } from '@dft/shared/services/entrega.service';
import { FormacaoService } from '@dft/shared/services/formacao.service';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'dft-entrega-formacao',
  templateUrl: './entrega-formacao.component.html',
  styleUrls: ['./entrega-formacao.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntregaFormacaoComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() entrega: Entrega;

  readonly = false;

  formacoes = FORMACAO_MAP;
  formacaoEnum = FormacaoEnum;
  grandesAreas$: Observable<Formacao[]>;
  areasBasicas$: Observable<Formacao[]>;
  especialidades$: Observable<Formacao[]>;

  constructor(
    private mensagemService: MensagemService,
    private formacaoService: FormacaoService,
    private entregaService: EntregaService
  ) { }

  ngOnInit(): void {
    // Campo "especialidades" deve ser obrigatório
    // caso formação seja "Superior Especialista"
    this.form.controls.formacao.valueChanges.subscribe(value => {
      const field = this.form.controls.especialidades;
      if (value && value.id === FormacaoEnum.SE) {
        field.setValidators([Validators.required, Validators.minLength(1)]);
      } else {
        field.setValidators(null);
        field.setValue([]);
      }
      field.updateValueAndValidity();
    });

    // Verificar os campos do form no 'entrega-form.component.ts'
    this.form.controls._grandeArea.valueChanges.subscribe((value: Formacao) =>
      this.listarAreasBasicas(value)
    );
    this.form.controls._areaBasica.valueChanges.subscribe((value: Formacao) =>
      this.listarEspecialidades(value)
    );
    this.form.controls._especialidade.valueChanges.subscribe((value: Formacao) =>
      this.adicionarEspecialidade(value)
    );

    this.grandesAreas$ = this.formacaoService
      .listarGrandesAreas()
      .pipe(
        map(itens => itens.map(item => ({ ...item, id: item.codigo }))),
        catchError(err => {
          this.mensagemService.erro(err);
          return [];
        })
      );

    if (this.entrega && this.entrega.formacao) {
      this.form.controls.formacao.setValue(this.formacoes.find(f => f.id === this.entrega.formacao));

      if (this.entrega.formacao === FormacaoEnum.SE) {
        this.entregaService.listarFormacoes(this.entrega).subscribe(formacoes => {
          const formacoesComID = formacoes.map(f => ({ ...f, id: f.codigo }));
          this.form.controls.especialidades.setValue(formacoesComID);
        });
      }
    }
  }

  removerEspecialidade(especialidade: Formacao): void {
    const itens: Formacao[] = this.form.controls.especialidades.value;
    this.form.controls.especialidades.setValue(
      itens.filter(i => i.codigo !== especialidade.codigo));
  }

  private listarAreasBasicas(grandeArea?: Formacao): void {
    this.listarEspecialidades(null);
    if (!grandeArea) {
      this.areasBasicas$ = of([]);
      this.form.controls._areaBasica.setValue(null);
      return;
    }
    this.areasBasicas$ = this.formacaoService.listarAreasBasicas(grandeArea.codigo).pipe(
      map(itens => itens.map(item => ({ ...item, id: item.codigo }))),
    );
  }

  private listarEspecialidades(areaBasica?: Formacao): void {
    if (!areaBasica) {
      this.especialidades$ = of([]);
      return;
    }
    this.especialidades$ = this.formacaoService.listarEspecialidades(areaBasica.codigo).pipe(
      map(itens => itens.map(item => ({ ...item, id: item.codigo }))),
    );
  }

  private adicionarEspecialidade(especialidade?: Formacao): void {
    this.form.controls.especialidades.setValue([
      ...this.form.controls.especialidades.value, especialidade
    ]);

    // Limpa controles de seleção
    this.form.controls._grandeArea.setValue(null);
    this.mensagemService.info(`Especialidade "${especialidade.descricao}" adicionada à lista.`);
  }
}
