import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { Senha } from '@dft/shared/models/senha';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './senha.component.html',
  styleUrls: ['./senha.component.scss'],
})
export class SenhaFormComponent implements FormComponent {

  form: FormGroup;
  formValue: Senha = null;
  readonly = false;

  constructor(private formBuilder: FormBuilder) {
    const validator = (group: FormGroup) => {
      const pass = group.get('novaSenha').value;
      const confirmPass = group.get('confirmacaoSenha').value;
      return pass === confirmPass ? null : { notSame: true };
    };

    this.form = this.formBuilder.group({
      senhaAtual: ['', Validators.required],
      novaSenha: ['', Validators.required],
      confirmacaoSenha: ['', Validators.required],
    }, { validators: [validator] });
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  public setFormValue(value: Senha): void {
    this.formValue = value;
  }
}
