<div id="login-container">
  <div class="login-box">
    <div class="login-box-body">
      <form #form method="post" (ngSubmit)="autenticar()">

        <img src="../../../assets/logo_workforce.png" alt="Banner de Login DFT" id="banner-login-dft" />

        <ng-container *ngIf="exibirCamposLogin">
          <mat-form-field appearance="outline">
            <mat-label>{{ loginLabel }}</mat-label>
            <input #inputLogin matInput name="login" [minlength]="loginFieldMinLength" [maxlength]="loginFieldMaxLength" [formControl]="loginField"
              (input)="validarMinCaracteres(inputLogin.value)" autofocus>
            <mat-error *ngIf="loginField.errors?.required">O preenchimento do login é obrigatório</mat-error>
            <mat-error *ngIf="loginField.errors?.minlength">O login é composto de no mínimo {{loginFieldMinLength}} caracteres</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!exibirFormRecuperarSenha" appearance="outline">
            <mat-label>Digite sua senha</mat-label>
            <input matInput [type]="notHide ? 'text' : 'password'" name="senha" [formControl]="senhaField">
            <mat-icon matSuffix (click)="notHide = !notHide" style="cursor: pointer;">{{notHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="senhaField.invalid">O preenchimento da senha é obrigatório</mat-error>
          </mat-form-field>

          <input type="hidden" name="recaptcha" [formControl]="recaptcha" />
        </ng-container>

        <div id="login-btn-div" class="login-btn-div">

          <button
            mat-stroked-button
            id="login-button"
            color="primary"
            type="submit"
            [disabled]="botaoEntrarDesabilitado"
            *ngIf="!exibirFormRecuperarSenha">
            <i class="fa fa-fw fa-sign-in" aria-hidden="true"></i>
            Entrar
          </button>

          <button
            mat-stroked-button
            *ngIf="exibirCamposLogin && !exibirFormRecuperarSenha"
            (click)="exibirFormRecuperar()">
            Recuperar senha
          </button>

          <ng-container *ngIf="exibirFormRecuperarSenha">
            <button
              mat-raised-button
              color="warn"
              (click)="recuperar($event)">
              <i class="fa fa-sign-in" aria-hidden="true"></i>
              Recuperar
            </button>

            <button mat-button (click)="cancelarRecuperar()">
              Cancelar
            </button>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
