import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Orgao } from '@dft/shared/models/orgao';

@Component({
  templateUrl: './orgao-escolaridade-form.component.html',
  styleUrls: ['./orgao-escolaridade-form.component.scss'],
})
export class OrgaoEscolaridadeFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  orgao: Orgao;

  constructor(
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._formBuilder.group({
      id: this._formBuilder.control(''),
      orgao: this._formBuilder.control(''),
      nome: this._formBuilder.control(null),
    });
  }

  ngOnInit() {
    if (this.formValue) {
      this.orgao = this.formValue.orgao;
      if (this.formValue) {
        let id = 0;
        let nome = '';
        if (this.formValue.escolaridade) {
          id = this.formValue.escolaridade.id;
          nome = this.formValue.escolaridade.nome;
        }
        this.form.setValue({
          id,
          orgao: this.formValue.orgao,
          nome,
        });
      }
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
