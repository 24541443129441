import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SenhaFormComponent } from '@dft/layout/senha/senha.component';
import { DialogService, PromptConfig } from '@dft/shared/dialog/dialog.service';
import { MensagemService } from '@dft/shared/services/mensagem.service';
import { PessoaService } from '@dft/shared/services/pessoa.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/security/auth/auth.service';
import { Senha } from 'src/app/shared/models/senha';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private mensagemService: MensagemService,
    private pessoaService: PessoaService,
    private matDialog: MatDialog
  ) {}

  closeModal(): void {
    this.matDialog.closeAll();
  }

  get usuarioLogado$() {
    return this.authService.pessoaLogada$;
  }

  get perfil$() {
    return this.authService.perfilSelecionado$;
  }

  isUsuarioAutenticado(): Observable<boolean> {
    return this.authService.pessoaLogada$.pipe(map((p) => !!p));
  }

  isAutenticacaoLocal(): boolean {
    return this.authService.isAutenticacaoLocal();
  }

  logout() {
    this.authService.logout();
  }

  trocarSenha(): void {
    const promptConfig: PromptConfig = {
      formComponent: SenhaFormComponent,
      title: 'ALTERAR SENHA',
      close: 'CANCELAR',
      ok: 'ALTERAR',
    };

    this.dialogService.prompt(promptConfig).subscribe(
      (data: Senha) => {
        data.idUsuario = this.usuarioLogado$.value.id;
        this.pessoaService.trocarSenha(data).subscribe(
          () => this.mensagemService.info(`Senha alterada com sucesso!`),
          (error) => this.mensagemService.erro(error)
        );
      },
      (erro) => this.mensagemService.erro(erro)
    );
  }
}
