import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moedaReal'
})
export class MoedaRealPipe implements PipeTransform {
  transform(quantidade: number): string {
    return (quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
