<form [formGroup]="form">
  <input type="hidden" formControlName="id" />
  <input type="hidden" formControlName="nome" />
  
  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Descricao do Indicador
    </mat-label>
    <textarea aria-label="Descrição do Indicador"
      matInput
      rows="5"
      name="descricao"
      placeholder="Informe uma descrição para o indicador em edição."
      formControlName="descricao"
      required>
    </textarea>
  </mat-form-field>

  <input type="hidden" formControlName="tipo" />
  <input type="hidden" formControlName="ativo" />
</form>
