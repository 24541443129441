export enum TipoMetodologiaEnum {
  TIPICA = 'TIPICA',
  ATIPICA = 'ATIPICA',
  OUTRA = 'OUTRA',
}

export const TIPO_METODOLOGIA_MAP = new Map<TipoMetodologiaEnum, string>([
  [TipoMetodologiaEnum.TIPICA, 'Típica'],
  [TipoMetodologiaEnum.ATIPICA, 'Atípica'],
  [TipoMetodologiaEnum.OUTRA, 'Outra'],
]);
