<form [formGroup]="form" class="container-form">
  <input type="hidden" formControlName="id" />

  <mat-label attr.aria-hidden="true">
    Organização da Unidade Produtiva
  </mat-label>
  <dft-multiselect
    formControlName="orgao"
    placeholder="Selecione a organização da unidade"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeOrgaos"
    ordenacao="descricao"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>

  <br />

  <mat-grid-list cols="2" rowHeight="4:1">
    <mat-grid-tile>
      <mat-form-field appearance="outline" style="margin-right: 20px;">
        <mat-label attr.aria-hidden="true">Sigla da Unidade Produtiva</mat-label>
        <input
          matInput
          aria-label="Sigla da unidade"
          name="siglaUnidade"
          placeholder="Ex.: RH"
          formControlName="sigla"
          maxlength="10"
          [readonly]="readonly"
          required
          #siglaDaUnidade
        />
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Buscar dados da Unidade Produtiva pela Sigla'"
          title="Buscar dados da Unidade Produtiva pela Sigla"
          *ngIf="siglaDaUnidade.value.length >= 1 && isOcultarImportacao"
          (click)="buscarDadosPeloIdentificador(siglaDaUnidade.value)">
          <mat-icon attr.aria-hidden="true">search</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field appearance="outline">
        <mat-label attr.aria-hidden="true">Código da Unidade Produtiva</mat-label>
        <input
          aria-label="Código da Unidade Produtiva (opcional)"
          matInput
          name="codigoReferencia"
          formControlName="codigoReferencia"
          placeholder="Ex.: 12345"
          maxlength="15"
          #codigoUnidade
        />
        <button
          mat-icon-button
          matSuffix
          [attr.aria-label]="'Buscar dados da Unidade Produtiva pelo Código'"
          title="Buscar dados da Unidade Produtiva pelo Código"
          *ngIf="codigoUnidade.value.length >= 1 && isOcultarImportacao"
          (click)="buscarDadosPeloIdentificador(codigoUnidade.value)">
          <mat-icon attr.aria-hidden="true">search</mat-icon>
        </button>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Nome da Unidade Produtiva</mat-label>
    <input
      aria-label="Nome da Unidade Produtiva"
      matInput
      name="nomeUnidade"
      placeholder="Ex.: Recursos Humanos"
      formControlName="descricao"
      maxlength="255"
      [readonly]="readonly"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label attr.aria-hidden="true">Descrição da Unidade Produtiva</mat-label>
    <textarea
      matInput
      aria-label="Descrição da Unidade Produtiva"
      name="descricaoUnidade"
      cdkTextareaAutosize
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="5" maxlength="500"
      placeholder="Ex.: Descrição detalhada da Unidade Produtiva"
      formControlName="descricaoDetalhada"
      [readonly]="readonly"
    ></textarea>

  </mat-form-field>

  <mat-label attr.aria-hidden="true">Conjunto de Produto</mat-label>
  <dft-multiselect
    formControlName="categoriaServicos"
    placeholder="Selecione os Conjuntos de Produtos"
    [desabilitado]="readonly"
    [delineado]="true"
    [selecaoUnica]="false"
    [listaDeOpcoes]="listaDeCategorias"
    ordenacao="descricao"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>
  <br />

  <mat-label attr.aria-hidden="true">
    Unidade Produtiva Superior
  </mat-label>
  <dft-multiselect
    formControlName="unidadeSuperior"
    placeholder="Selecionar unidade superior (opcional)"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeUnidadesOrgao"
    ordenacao="descricao">
  </dft-multiselect>
</form>
