import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@dft/security/auth/auth.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { DimensionamentoHistorico } from '@dft/shared/models/dimensionamento-historico';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'dimensionamento-devolucao-form.component.html',
})
export class DimensionamentoDevolucaoFormComponent implements OnInit {
  form: FormGroup;
  formValue = null;
  readonly = false;

  dimensionamento: Dimensionamento;

  constructor(
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
  ) {
    this.form = this._formBuilder.group({
      responsavel: this._formBuilder.control(this._auth.pessoaLogada$.value.nome),
      cpf: this._formBuilder.control(this._auth.pessoaLogada$.value.cpf),
      descricao: this._formBuilder.control('', [Validators.maxLength(1000)]),
    });
  }

  obtemMensagemDeErro() {
    return this.form.controls['descricao'].hasError('required')
      ? 'O campo Descrição dos Ajustes é obrigatório.'
      : this.form.controls['descricao'].hasError('maxlength')
        ? 'Descrição não pode conter mais que 1000 caracteres.'
        : '';
  }

  ngOnInit() {
    this.dimensionamento = this.formValue['dimensionamento'] as Dimensionamento;

    const _historico = this.formValue['historico'] as DimensionamentoHistorico;

    if ( _historico ) {
      this.form.controls['responsavel'].setValue(_historico.nomePessoa);
      this.form.controls['cpf'].setValue(_historico.cpfPessoa);
      this.form.controls['descricao'].setValue(_historico.descricaoDevolucao);
    }
    this.form.controls['responsavel'].disable();
    this.form.controls['cpf'].disable();
    if (this.readonly) {
      this.form.controls['descricao'].disable();
    }
  }

  public setFormValue(value: object): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

}
