import { Lista } from '../models/lista';

export enum TipoOrgaoEnum {
  ADMINISTRACAO_PUBLICA_DIRETA = 'ADMINISTRACAO_PUBLICA_DIRETA',
  AGENCIA_REGULADORA = 'AGENCIA_REGULADORA',
  BANCO = 'BANCO',
  AUTARQUIA = 'AUTARQUIA',
  CASA_LEGISLATIVA = 'CASA_LEGISLATIVA',
  CONSELHO_PROFISSIONAL = 'CONSELHO_PROFISSIONAL',
  ESTADUAL_DISTRITAL = 'ESTADUAL_DISTRITAL',
  ESTATAL = 'ESTATAL',
  FUNCOES_ESSENCIAIS_A_JUSTICA = 'FUNCOES_ESSENCIAIS_A_JUSTICA',
  FUNDACAO = 'FUNDACAO',
  FUNDO = 'FUNDO',
  INSTITUICAO_DE_ENSINO = 'INSTITUICAO_DE_ENSINO',
  MILITAR = 'MILITAR',
  MINISTERIO = 'MINISTERIO',
  ORGAO_EXECUTIVO = 'ORGAO_EXECUTIVO',
  PARAESTATAL = 'PARAESTATAL',
  TRIBUNAL = 'TRIBUNAL',
  UNIDADE_DE_SAUDE = 'UNIDADE_DE_SAUDE',
  OUTROS = 'OUTROS',
}

export const TIPO_ORGAO_MAP: Lista[] = [
  { id: TipoOrgaoEnum.ADMINISTRACAO_PUBLICA_DIRETA, descricao: 'Administração Pública Direta', detalhamento: ''},
  { id: TipoOrgaoEnum.AGENCIA_REGULADORA, descricao: 'Agência Reguladora', detalhamento: ''},
  { id: TipoOrgaoEnum.AUTARQUIA, descricao: 'Autarquia', detalhamento: ''},
  { id: TipoOrgaoEnum.BANCO, descricao: 'Banco', detalhamento: '' },
  { id: TipoOrgaoEnum.CASA_LEGISLATIVA, descricao: 'Casa Legislativa', detalhamento: '' },
  { id: TipoOrgaoEnum.CONSELHO_PROFISSIONAL, descricao: 'Conselho Profissional', detalhamento: '' },
  { id: TipoOrgaoEnum.ESTADUAL_DISTRITAL, descricao: 'Estadual/Distrital', detalhamento: '' },
  { id: TipoOrgaoEnum.ESTATAL, descricao: 'Estatal', detalhamento: '' },
  { id: TipoOrgaoEnum.FUNCOES_ESSENCIAIS_A_JUSTICA, descricao: 'Funções Essenciais à Justiça', detalhamento: '' },
  { id: TipoOrgaoEnum.FUNDACAO, descricao: 'Fundação', detalhamento: ''},
  { id: TipoOrgaoEnum.FUNDO, descricao: 'Fundo', detalhamento: '' },
  { id: TipoOrgaoEnum.INSTITUICAO_DE_ENSINO, descricao: 'Instituição de Ensino', detalhamento: '' },
  { id: TipoOrgaoEnum.MILITAR, descricao: 'Militar', detalhamento: '' },
  { id: TipoOrgaoEnum.MINISTERIO, descricao: 'Ministério', detalhamento: '' },
  { id: TipoOrgaoEnum.ORGAO_EXECUTIVO, descricao: 'Organização Executivo (Adm. Direta)', detalhamento: '' },
  { id: TipoOrgaoEnum.PARAESTATAL, descricao: 'Paraestatal', detalhamento: '' },
  { id: TipoOrgaoEnum.TRIBUNAL, descricao: 'Tribunal', detalhamento: '' },
  { id: TipoOrgaoEnum.UNIDADE_DE_SAUDE, descricao: 'Unidade de Saúde', detalhamento: '' },
  { id: TipoOrgaoEnum.OUTROS, descricao: 'Outros', detalhamento: ''},
];
