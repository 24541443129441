<h2 *ngIf="dialog.title" mat-dialog-title>
  {{ dialog.title }}
</h2>
<mat-dialog-content>
  <ng-container
    dftDialog
    #contentRef="dftDialog"
    [content]="dialog.formComponent"
    [value]="data.value"
    [readonly]="data.readOnly"
  >
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    *ngIf="dialog.ok"
    mat-stroked-button
    color="primary"
    [disabled]="contentRef.form.invalid"
    (click)="dialogRef.close(contentRef.form.getRawValue())"
  >
    <i class="material-icons-outlined" aria-hidden="true">
      {{ dialog.okIcon || 'check_circle_outline' }}
    </i>
    {{ dialog.ok }}
  </button>
  <button mat-stroked-button mat-dialog-close color="warn">
    <i class="material-icons-outlined" aria-hidden="true">
      {{ dialog.closeIcon || 'cancel' }}
    </i>
    {{ dialog.close }}
  </button>
  <button *ngIf="dialog.acaoAdicionalLabel" mat-stroked-button 
    color="primary"
    (click)="dialogRef.close(dialog.acaoAdicionalLabel)">
    <i class="material-icons-outlined" aria-hidden="true">
      {{ dialog.acaoAdicionalIcon || 'check_circle_outline' }}
    </i>
    {{ dialog.acaoAdicionalLabel }}
  </button>
</mat-dialog-actions>
