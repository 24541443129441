import { Pipe, PipeTransform } from '@angular/core';
import { PessoaPerfil } from './../models/pessoa-perfil';
import { TipoPerfilEnum } from '../enums/tipo-perfil.enum';

@Pipe({
  name: 'descricaoPerfil',
})
export class DescricaoPerfilPipe implements PipeTransform {
  transform(pessoaPerfil: PessoaPerfil): string {
    if (
      pessoaPerfil.descricaoPerfil === TipoPerfilEnum.GESTOR_UNIDADE &&
      !pessoaPerfil.validador
    ) {
      return TipoPerfilEnum.GESTOR_UNIDADE + ' - Não Validador';
    }
    return pessoaPerfil.descricaoPerfil;
  }
}
