import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Classe de configuração do paginator em PT-BR.
 */
@Injectable()
export class MatPaginatorBr extends MatPaginatorIntl {

  firstPageLabel = 'Primeira página';
  lastPageLabel = 'Última página';
  itemsPerPageLabel = 'Itens por página:';
  nextPageLabel = 'Próxima pagina';
  previousPageLabel = 'Página anterior';

  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    const newLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < newLength
      ? Math.min(startIndex + pageSize, newLength)
      : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + newLength;
  };
}
