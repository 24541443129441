import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormComponent } from '@dft/shared/dialog/dialog.service';
import { Dimensionamento } from '@dft/shared/models/dimensionamento';
import { Unidade } from '@dft/shared/models/unidade';
import { ParametroService } from '@dft/shared/services/parametro.service';
import * as StringUtil from '@dft/shared/util/string.util';
import { UnidadeService } from 'src/app/shared/services/unidade.service';
import { ParametroEnum } from './../../../../../shared/enums/parametro.enum';


export interface SelecaoPessoasFormData {
  idOrgao: number;
  idUnidade: number;
  pessoas: number[];
}

@Component({
  templateUrl: './selecao-pessoas-form.component.html',
  styleUrls: ['./selecao-pessoas-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelecaoPessoasFormComponent implements OnInit, FormComponent {
  @ViewChild(MatPaginator, { static: true }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: true }) private ordenador: MatSort;

  form: FormGroup;
  formValue: SelecaoPessoasFormData;
  readonly = false;
  habilitaAtividadeApoio: boolean;
  dimensionamento: Dimensionamento;

  datasource: MatTableDataSource<AbstractControl>;
  colunasExibidas = ['pessoa', 'matricula', 'cargaHoraria', 'funcao'];
  tamanhoDosResultados = 0;
  filtro = '';

  constructor(
    private _formBuilder: FormBuilder,
    private _unidadeService: UnidadeService,
    private _parametroService: ParametroService
  ) {
    this.form = this._formBuilder.group({
      pessoas: new FormArray([]),
    });
  }

  get pessoas() {
    return this.form.get('pessoas') as FormArray;
  }

  ngOnInit() {
    this.recuperarDados();
    this.recuperaSeHabilitaAtividadeApoio();
    this.recuperaDimensionamento();
  }

  private recuperaDimensionamento() {
    this.dimensionamento = this.formValue['dimensionamento'];
  }

  private recuperaSeHabilitaAtividadeApoio() {
    this._parametroService
      .buscarParametro(ParametroEnum.PARAMETRO_CONFIG_HABILITAR_ATIVIDADE_APOIO)
      .subscribe(parametro => {
        this.habilitaAtividadeApoio = parametro.valor === 'false' ? false : true;
      });
  }

  private recuperarDados() {
    const idUnidade = this.formValue['idUnidade'];
    const pessoasFuncionalSelecionadas = this.formValue['pessoasFuncional'] as Array<number>;
    const unidade = new Unidade(idUnidade);

    this._unidadeService
      .listarPessoasDaUnidade(unidade)
      .subscribe((resposta) => {

        this.pessoas.clear();
        resposta.forEach((pessoa) => {
          pessoa.pessoaDadosFuncionais.forEach((pessoaFuncional) => {
            if (!pessoasFuncionalSelecionadas.includes(pessoaFuncional.id)) {
              this.pessoas.push(
                this._formBuilder.group({
                  pessoa: this._formBuilder.control(pessoa),
                  pessoaFuncional: this._formBuilder.control(pessoaFuncional),
                  selecionado: this._formBuilder.control(false),
                  funcao: this._formBuilder.control(null),
                })
              );
            }
          });
        });
        this.datasource = new MatTableDataSource(this.pessoas.controls);
        this.tamanhoDosResultados = this.pessoas.controls.length;
        this.datasource.paginator = this.paginador;
        this.configuraTabela();
      });
  }

  configuraTabela(): void {
    this.datasource.paginator = this.paginador;

    // Configura sort para acessar propriedades de objetos internos
    this.datasource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (typeof data.controls.pessoa.value[sortHeaderId] === 'string') {
        return StringUtil.getStringSemAcento(data.controls.pessoa.value[sortHeaderId]);
      }

      return data.controls.pessoa.value[sortHeaderId];
    };

    this.datasource.sort = this.ordenador;
    this.datasource.filterPredicate = (
      item: AbstractControl,
      filter: string
    ) => {
      const nomeSemAcento = StringUtil.getStringSemAcento(item.value.pessoa.nome);
      const filtroSemAcento = StringUtil.getStringSemAcento(filter);
      return nomeSemAcento.includes(filtroSemAcento) ||
        item.value.pessoa.cpf.includes(filtroSemAcento);
    };
  }

  filtrar(filtro: string) {
    this.datasource.filter = filtro.trim().toLowerCase();
    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  public setFormValue(value: SelecaoPessoasFormData): void {
    this.formValue = value;
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }
}
