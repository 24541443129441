<div *ngIf="isUsuarioAutenticado() | async">
  <ng-container *ngIf="{usuarioLogado: usuarioLogado$ | async, perfil: perfil$ | async} as dadosUsuario">

<mat-card>
  <mat-card-header>
       <mat-card-title>Seja Bem Vindo(a)</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>Olá <span class="destaque">{{ dadosUsuario.usuarioLogado.nome }}</span>.</p>

    <p>Este é o seu perfil de <span class="destaque">{{ dadosUsuario.perfil.descricaoPerfilUnidade }}</span>.</p>

    <p>Caso desejar alterar o perfil, clique no seu nome no canto superior a direito.</p>

    <p>Quer saber como usar o sistema? Acesse nosso módulo de Tutoriais no menu lateral.</p>
  </mat-card-content>
</mat-card>

</ng-container>
</div>
