<form [formGroup]="form" class="container-form">
  <br/>
  <input type="hidden" formControlName="id" />

  <mat-label attr.aria-hidden="true">
    Conjunto de Produto
  </mat-label>
  <a
    role="button"
    aria-roledescription="Botão de informação"
    mat-icon-button
    color="primary"
    matTooltip="Entrega já vinculada em Ciclo de Produtividade. Não é possível alterar a Conjunto de Produto ou o Código.
      Você poderá excluir esta entrega e criar outra com a Conjunto de Produto ou Código desejados"
    attr.aria-label="Entrega já vinculada em Ciclo de Produtividade. Não é possível alterar a Conjunto de Produto ou o Código.
    Você poderá excluir esta entrega e criar outra com a Conjunto de Produto ou Código desejados"
    *ngIf="temVinculoDimensionamento$ | async"
  >
    <mat-icon class="material-icons-outlined md-18"> info </mat-icon>
  </a>
  <dft-multiselect
    formControlName="categoriaServico"
    placeholder="Selecione a Conjunto de Produto"
    [desabilitado]="!isNaoValidada && (!isNovaEntrega || (temVinculoDimensionamento$ | async))"
    ordenacao="descricao"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="categorias$ | async"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Atividade
    </mat-label>
    <input aria-label="Atividade"
      matInput
      name="atividade"
      placeholder="Ex.: Contratação de Pessoal"
      formControlName="atividade"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Fluxo de Trabalho
    </mat-label>
    <textarea
      matInput aria-label="Fluxo de Trabalho"
      name="fluxoTrabalho"
      cdkTextareaAutosize
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="5"
      placeholder="Deve-se numerar o fluxo na ordem de acontecimento."
      formControlName="fluxoTrabalho"
      required
    ></textarea>
  </mat-form-field>

  <mat-grid-list cols="3" rowHeight="90px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline">
        <mat-label aria-hidden="true">Base Entrega</mat-label>
        <input aria-label="Código da Base do Produto com 6 dígitos"
          matInput
          name="codBaseEntrega"
          mask="0000.0000"
          formControlName="codBaseEntrega"
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field appearance="outline">
        <mat-label aria-hidden="true">Código do Produto</mat-label>
        <input aria-label="Código do Produto com 8 dígitos"
          matInput
          name="codigo"
          mask="0000.0000"
          formControlName="codigo"
        />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="6" rowHeight="90px">
    <mat-grid-tile colspan="6">
      <mat-form-field appearance="outline">
        <mat-label aria-hidden="true">Nome do Produto</mat-label>
        <input aria-label="Nome do Produto"
          matInput
          name="nome"
          placeholder="Ex.: Lista de Demandas Externas"
          formControlName="nome"
          required
        />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">Fonte</mat-label>
    <input
      matInput aria-label="Fonte"
      name="fonte"
      formControlName="fonte"
    />
  </mat-form-field>

  <mat-label attr.aria-hidden="true">
    Situação
  </mat-label>
  <dft-multiselect
    formControlName="status"
    placeholder="Selecione a situação"
    [delineado]="true"
    [selecaoUnica]="true"
    [listaDeOpcoes]="statusMap"
    permitirFiltroDePesquisa="false"
    [desabilitado]="(isGestorGeral$ | async) === false || isNovaEntrega"
    #status>
  </dft-multiselect>

  <br/>

  <mat-form-field appearance="outline" *ngIf="status.control?.value?.id === statusEnum.INATIVA">
    <mat-label aria-hidden="true">Justificativa</mat-label>
    <input aria-label="Justificativa"
      matInput
      name="justificativa"
      placeholder="Justificativa de inativação da entrega"
      formControlName="justificativa"
      minlength="5"
      maxlength="100"
    />
  </mat-form-field>
</form>
